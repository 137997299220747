import React from 'react';
import styles from './ConsultantSection.module.css';
import { GlobalContext } from '../../GlobalContext';
import { people, peopleEng } from '../../Data/data';
import ConsultantCard from './ConsultantCard/ConsultantCard';

export default function ConsultantSection() {

  const { userLanguage } = GlobalContext();
  const content = userLanguage === 'sv' ? people : peopleEng;

  return (
    <section className={`${styles.sectionWrapper} sectionWrapper`}>
      {content.map((consult, index) => (consult.isConsult && <ConsultantCard key={index} data={consult}/>))}
    </section>
  )
}
