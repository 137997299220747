import React from 'react';
import styles from './AboutCard.module.css';
import { FaLinkedinIn, FaRegEnvelope } from 'react-icons/fa';

export default function AboutCard(person) {
  const data = { ...person.data };
  const image = require(`../../../images/${data.imgUrlSquare}`);

  return (
    <article className={styles.cardWrapper}>
      <div className={styles.imgWrapper}>
        <img src={image} alt={data.imgSquareAlt} className={styles.img} />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.iconWrapper}>
          <a
            href={data.mail}
            target="_blank"
            rel="noreferrer noopener"
            className={styles.iconCircle}
            aria-label={`Open your e-mail client in a new window`}
          >
            <FaRegEnvelope className={styles.icon} />
          </a>
          <a
            href={data.linkedin}
            target="_blank"
            rel="noreferrer noopener"
            className={styles.iconCircle}
            aria-label={`Open Linkedin in a new window`}
          >
            <FaLinkedinIn className={styles.icon} />
          </a>
        </div>
        <div className={styles.txtWrapper}>
          <h4 className="titleTxt">{data.name}</h4>
          <div className={styles.titleWrapper}>
            <p
              className={`${styles.title} bodyTxt mediumTxt mediumBold leftTxt`}
            >
              {data.title}
            </p>
            <p
              className={`${styles.title} bodyTxt mediumTxt mediumBold leftTxt`}
            >
              {data.title2}
            </p>
          </div>
        </div>
      </div>
    </article>
  );
}
