import React from 'react';
import styles from './ConsultantCard.module.css';
import { FaLinkedinIn, FaRegEnvelope } from 'react-icons/fa'; 
import { GlobalContext } from '../../../GlobalContext';
import Heading from '../../Heading/Heading';
import ConsultantProject from './ConsultantProject/ConsultantProject';
import ReviewsSection from '../../ReviewsSection/ReviewsSection';

export default function ConsultantCard(consult) {

    const { deviceType } = GlobalContext();
    const data = { ...consult.data };
    const image = require(`../../../images/${data.consultantImg}`)

    return (
        <div className={`${styles.background} background bgM1`}>
            <article className={styles.sectionWrapper}>
                <Heading data={data.consultingSecTxt} position={deviceType === 'Desktop' ? 'flex-start' : deviceType === 'Tablet' ? 'center' : 'center'} />
                <div className={styles.topContentWrapper}>
                    <div className='paragraphWrapper'>
                        {data.consultLongerText.map((paragraph, index) => (<p className='bodyTxt' key={index}>{paragraph}</p>))}
                    </div>
                    <div className={styles.iconWrapper}>
                        <a href={data.mail} target='_blank' rel='noreferrer noopener' className={styles.iconCircle} aria-label={`Open E-mail in a new window`}>
                            <FaRegEnvelope className={styles.icon} />
                        </a> 
                        <a href={data.linkedin} target='_blank' rel='noreferrer noopener' className={styles.iconCircle} aria-label={`Open Linkedin in a new window`}>
                            <FaLinkedinIn className={styles.icon} />
                        </a> 
                    </div>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.imgWrapper}>
                        <img src={image} alt='' className={styles.img}/>
                    </div>
                    <div className={styles.projectWrapper}>
                        {data.projects.map((project, index) => (<ConsultantProject key={index} data={project}/>))}
                    </div>
                </div>
                
            </article>
            {data.isConsultingReviews && <ReviewsSection text={data.consultingReviewSecTxt} content={data.consultingReviews} />}
        </div>
    )
}
