import React from 'react';
import styles from './ButtonShowMore.module.css';
import { FaChevronDown } from 'react-icons/fa';

export default function ButtonShowMore({onClick, value}) {
    
    return (
        <button className={styles.button}onClick={onClick}>{value}<FaChevronDown/></button>
    )
}


