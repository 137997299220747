import React from 'react';
import { GlobalContext } from '../../GlobalContext';
import { consultingIntroTxt, consultingIntroTxtEng } from '../../Data/data';
import Intro from '../IntroSection/IntroSection';
import ConsultantSection from '../ConsultantSection/ConsultantSection';

export default function ConsultingPage() {
  
  const { userLanguage } = GlobalContext();
  const text = userLanguage === 'sv' ? consultingIntroTxt : consultingIntroTxtEng;
  
  return (
    <main className='pageWrapper'>
      <Intro data={text}/>
      <ConsultantSection />
    </main>
  )
}