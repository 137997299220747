import React, { useState } from 'react';
import Modal from 'react-modal';
import styles from './ReviewsSection.module.css';
import { FaChevronLeft, FaChevronRight  } from 'react-icons/fa'; 
import { GlobalContext } from '../../GlobalContext';
import ReviewCard from './ReviewCard/ReviewCard';
import Heading from '../Heading/Heading';
import ButtonShowMore from '../ButtonShowMore/ButtonShowMore';
import ReviewModal from '../ReviewModal/ReviewModal';

export default function ReviewsSection({text, content, colored}) {

    const { deviceType, reviewModalIsOpen, setReviewModalIsOpen } = GlobalContext();
    const [reviewModalContent, setReviewModalContent] = useState();

    const handleReviewModalContent = (content) => {
        setReviewModalContent(content)
    }

    const MobileLayout = () => {

        const [displayedIndex, setDisplayedIndex] = useState(2);

        const handleNextClick = () => {
            setDisplayedIndex((prevIndex) => prevIndex + 2);
        };

        return (
            <div className={styles.cardWrapperM}>
                <div className={styles.cardContainerM}>
                    {content.slice(0, displayedIndex).map((review, index) => (<ReviewCard key={index} data={review} linkTxt={text.linkTxt}  handleReviewModalContent={handleReviewModalContent}/>))}
                </div>
                {displayedIndex < content.length && (
                    <ButtonShowMore value={text.btnTxt} onClick={handleNextClick}/>
                )}
            </div>
        )
    }

    const DesktopLayout = () => {

        const [displayedIndex, setDisplayedIndex] = useState(0);
        const cardsPerPage = deviceType === 'Desktop' ? 3 : 2; 

        const handleNextClick = () => {
            setDisplayedIndex((prevIndex) => prevIndex + cardsPerPage);
        };
        const handleBackClick = () => {
            setDisplayedIndex((prevIndex) => prevIndex - cardsPerPage);
        }

        const displayReviewCards = () => {
            return content.slice(displayedIndex, displayedIndex + cardsPerPage).map((review, index) => (
                <ReviewCard key={index} data={review} linkTxt={text.linkTxt} handleReviewModalContent={handleReviewModalContent}/>
            ));
        };

        return(
            <div className={styles.cardWrapperD}>
                {content.length > 3 &&
                    <div className={styles.arrowWrapper}>
                        <button className={displayedIndex > 0 ? styles.btn : styles.btn + ' ' + styles.btnDisabled} 
                                onClick={handleBackClick} 
                                disabled={displayedIndex < cardsPerPage}
                                aria-label='scroll left'>
                                    <FaChevronLeft className={styles.btnLeft}/>
                        </button>
                        <button className={displayedIndex + cardsPerPage < content.length ? styles.btn : styles.btn + ' ' + styles.btnDisabled} 
                                onClick={handleNextClick} 
                                disabled={displayedIndex + cardsPerPage > content.length}
                                aria-label='scroll right'>
                                    <FaChevronRight className={styles.btnRight}/>
                        </button>
                    </div>
                }
                <div className={styles.cardContainerD}>
                    {displayReviewCards()}
                </div>
            </div>
        )
    }

    return (
        <div className={colored ? `background bgM1` : styles.background}>
            <section className={`${styles.sectionWrapper} sectionWrapper`}>
                <Heading data={text} position={deviceType === 'Desktop' ? 'flex-start' : deviceType === 'Tablet' ? 'flex-start' : 'center'} />
                { 
                        deviceType === 'Mobile' ? <MobileLayout />
                    :   deviceType === 'Tablet' ? <MobileLayout />
                    :   <DesktopLayout />
                }
                <Modal         
                    isOpen = {reviewModalIsOpen}
                    onRequestClose = {() => setReviewModalIsOpen(false)}
                    className = 'modalWrapper'
                    overlayClassName = 'modalOverlay'>
                        <ReviewModal modalType='reviews' content={reviewModalContent} />
                </Modal>
            </section>
        </div>
    )
}
