import React from 'react';
import styles from './ContactBanner.module.css';
import { GlobalContext } from '../../GlobalContext';
import { contactBannerSecTxt, contactBannerSecTxtEng } from '../../Data/data';
import Heading from '../Heading/Heading';
import ButtonContact from '../ButtonContact/ButtonContact';

export default function ContactBanner() {
  const { deviceType, userLanguage } = GlobalContext();
  const text =
    userLanguage === 'sv' ? contactBannerSecTxt : contactBannerSecTxtEng;

  return (
    <div className="background bgM3">
      <section className={styles.sectionWrapper}>
        <Heading
          data={text}
          position={
            deviceType === 'Desktop'
              ? 'flex-start'
              : deviceType === 'Tablet'
              ? 'flex-start'
              : 'center'
          }
          color="white"
        />
        <p
          className={
            deviceType === 'Mobile'
              ? 'bodyTxt whiteTxt centerTxt'
              : 'bodyTxt whiteTxt'
          }
          style={deviceType === 'Desktop' ? { maxWidth: '65%' } : {}}
        >
          {text.bodyTxt}
        </p>
        <ButtonContact
          value={text.btnTxt}
          align={deviceType === 'Mobile' ? 'center' : 'flex-start'}
        />
      </section>
    </div>
  );
}
