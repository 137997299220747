import React from 'react';
import styles from './GdprModal.module.css';
import { IoCloseOutline } from 'react-icons/io5';
import { GlobalContext } from '../../GlobalContext';
import { gdprSecTxt, gdprSecTxtEng } from '../../Data/data';

function GdprModal() {

  const { userLanguage, setGdprModalIsOpen } = GlobalContext();
  const text = userLanguage === 'sv' ? gdprSecTxt : gdprSecTxtEng;

  return (
    <section className={styles.modalWrapper}>
        <IoCloseOutline className={styles.icon} onClick={() => setGdprModalIsOpen(false)}/>
        <article className={styles.contentWrapper}>   
            <h4>{text.heading}</h4>
            <div className='paragraphWrapper'>
                {text.text.map((paragraph, index) => (<p className='bodyTxt' key={index}>{paragraph}</p>))}
            </div>
        </article>
    </section>
  )
}

export default GdprModal