import React from 'react';
import styles from './IntroSection.module.css';
import { GlobalContext } from '../../GlobalContext';
import Heading from '../Heading/Heading';

export default function IntroSection(props) {
  const { deviceType } = GlobalContext();
  const data = { ...props.data };
  const image = require(`../../images/${data.imgUrl}`);

  return (
    <section id="intro" className={`${styles.sectionWrapper} sectionWrapper`}>
      <div className={styles.txtWrapper}>
        <Heading
          data={data}
          position={
            deviceType === 'Desktop'
              ? 'flex-start'
              : deviceType === 'Tablet'
              ? 'center'
              : 'center'
          }
        />
        <div className="paragraphWrapper">
          {data.bodyTxt.map((paragraph, index) => (
            <p className="bodyTxt" key={index}>
              {paragraph}
            </p>
          ))}
        </div>
        {data.quoteTxt1 && (
          <article className={styles.quoteWrapper}>
            <p className="quoteTxt">{data.quoteTxt1}</p>
            <p className="quoteTxt quoteBy">{data.quoteTxt2}</p>
          </article>
        )}
        {data.poppingTxt && (
          <p className={`bodyTxt mediumTxt mediumBold`}>{data.poppingTxt}</p>
        )}
      </div>
      <div className={styles.imgWrapper}>
        <img src={image} alt={data.imgAlt} className={styles.img} />
      </div>
    </section>
  );
}
