import React from 'react';
import styles from './ReviewCard.module.css';
import { BiSolidQuoteAltRight } from "react-icons/bi";
import { GlobalContext } from '../../../GlobalContext';

export default function ReviewCard(props) {
    const { setReviewModalIsOpen } = GlobalContext();
    const data = { ...props.data };

    const truncateText = () => {
        const text = data.review[0];
        const maxLength = 150;
        const truncatedText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

        return truncatedText;
    }

    return (
        <article className={styles.cardWrapper}>
            <div className={styles.topWrapper}>
                <BiSolidQuoteAltRight className={styles.icon}/>
                <div className={styles.txtWrapper}>
                    <p className='bodyTxt mediumTxt semiBold'>{data.name}</p>
                    <p className='bodyTxt'>{data.role}</p>
                </div>
            </div>
            <div className={styles.bottomWrapper}>
                <p className='bodyTxt italicTxt'>"{truncateText()}"</p>
                <p className={styles.readMore} onClick={() => {setReviewModalIsOpen(true); props.handleReviewModalContent(data)}}>{props.linkTxt}</p>
            </div>
        </article>
    )
}


