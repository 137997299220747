import React from 'react';
import styles from './AboutSection.module.css';
import { GlobalContext } from '../../GlobalContext';
import {
  people,
  peopleEng,
  aboutSecTxt,
  aboutSecTxtEng,
} from '../../Data/data';
import Heading from '../Heading/Heading';
import AboutCard from './AboutCard/AboutCard';

export default function AboutSection() {
  const { userLanguage, deviceType } = GlobalContext();
  const text = userLanguage === 'sv' ? aboutSecTxt : aboutSecTxtEng;
  const content = userLanguage === 'sv' ? people : peopleEng;

  return (
    <section className="background bgM2">
      <section id="about" className={`sectionWrapper`}>
        <Heading
          data={text}
          position={
            deviceType === 'Desktop'
              ? 'flex-start'
              : deviceType === 'Tablet'
              ? 'center'
              : 'center'
          }
        />
        <div className={styles.cardWrapper}>
          {content.map((person) => (
            <AboutCard key={person.id} data={person} />
          ))}
        </div>
      </section>
    </section>
  );
}
