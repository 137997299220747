import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Modal from 'react-modal';
import { ContextProvider } from './GlobalContext';
import HomePage from './Components/HomePage/HomePage';
import HeaderSection from './Components/HeaderSection/HeaderSection';
import ContactSection from './Components/ContactSection/ContactSection';
import LandingSection from './Components/LandingSection/LandingSection.jsx';
import ConsultingPage from './Components/ConsultingPage/ConsultingPage';
import LecturesPage from './Components/LecturesPage/LecturesPage';
import ActivitiesPage from './Components/ActivitiesPage/ActivitiesPage';
import FooterSection from './Components/FooterSection/FooterSection';
import SupportBanner from './Components/SupportBanner/SupportBanner';

export default function App() {
  Modal.setAppElement('#root');

  return (
    <BrowserRouter>
      <ContextProvider>
        <HeaderSection />
        <LandingSection />
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path='start' element={<HomePage />} /> */}
          <Route path="konsulting" element={<ConsultingPage />} />
          <Route path="forelasningar" element={<LecturesPage />} />
          <Route path="aktiviteter" element={<ActivitiesPage />} />
        </Routes>
        <ContactSection />
        <SupportBanner />
        <FooterSection />
      </ContextProvider>
    </BrowserRouter>
  );
}
