import React from 'react';
import styles from './SupportBanner.module.css';
import { GlobalContext } from '../../GlobalContext';
import { supportSecTxt, supportSecTxtEng } from '../../Data/data';

export default function SupportBanner() {
  const { userLanguage, deviceType } = GlobalContext();
  const text = userLanguage === 'sv' ? supportSecTxt : supportSecTxtEng;
  const logo = require(`../../images/aan_logos/${text.logoUrl}`);

  const MobileLayout = () => {
    return (
      <section className={styles.mobileWrapper}>
        <h3 className="largeTxt whiteTxt centerTxt">{text.mainTxtMob}</h3>
        <img src={logo} alt={text.logoAlt} className={styles.logo} />
        <p className="bodyTxt whiteTxt centerTxt">
          {text.bodyTxt}
          <a
            href={`http://www.${text.linkTxt}`}
            className={styles.link}
            target="_blank"
            rel="noreferrer noopener"
          >
            {text.linkTxt}
          </a>
          {text.bodyTxt2}
        </p>
      </section>
    );
  };

  const DesktopLayout = () => {
    return (
      <section className={styles.desktopWrapper}>
        <div className={styles.txtWrapper}>
          <h3 className="largeTxt whiteTxt centerTxt">{text.mainTxtDesk}</h3>
          <p className="bodyTxt whiteTxt centerTxt">
            {text.bodyTxt}
            <a
              href={`http://www.${text.linkTxt}`}
              className={styles.link}
              target="_blank"
              rel="noreferrer noopener"
            >
              {text.linkTxt}
            </a>
            {text.bodyTxt2}
          </p>
        </div>
        <img src={logo} alt={text.logoAlt} className={styles.logo} />
      </section>
    );
  };

  return (
    <div className="background bgM3">
      {deviceType === 'Mobile' ? (
        <MobileLayout />
      ) : deviceType === 'Tablet' ? (
        <DesktopLayout />
      ) : (
        <DesktopLayout />
      )}
    </div>
  );
}
