import React from 'react';
import styles from './LecturerCard.module.css';
import { FaLinkedinIn, FaRegEnvelope } from 'react-icons/fa';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { GlobalContext } from '../../../GlobalContext';
import Heading from '../../Heading/Heading';
import ReviewsSection from '../../ReviewsSection/ReviewsSection';

export default function LecturerCard(lecturer) {
  const { deviceType } = GlobalContext();
  const data = { ...lecturer.data };
  const image = require(`../../../images/${data.lecturerImg}`);

  return (
    <div className={`${styles.background} background`}>
      <article className={styles.sectionWrapper}>
        <Heading
          data={data.lecturerSecTxt}
          position={
            deviceType === 'Desktop'
              ? 'flex-start'
              : deviceType === 'Tablet'
              ? 'flex-start'
              : 'center'
          }
        />
        <div className={styles.contentWrapper}>
          {deviceType === 'Mobile' && (
            <div className={styles.topWrapper}>
              <div className={styles.topTxtWrapper}>
                <div className={styles.titleWrapper}>
                  <p className={`${styles.title} ${styles.center}`}>
                    {data.title}
                  </p>
                  <p className={`${styles.title} ${styles.center}`}>
                    {data.title2}
                  </p>
                </div>
              </div>
              <div className={styles.iconWrapper}>
                <a
                  href={data.mail}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={styles.iconCircle}
                  aria-label={`Open E-mail in a new window`}
                >
                  <FaRegEnvelope className={styles.icon} />
                </a>
                <a
                  href={data.linkedin}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={styles.iconCircle}
                  aria-label={`Open Linkedin in a new window`}
                >
                  <FaLinkedinIn className={styles.icon} />
                </a>
              </div>
            </div>
          )}
          <div className={styles.imgWrapper}>
            <img src={image} alt={data.lecturerAlt} className={styles.img} />
          </div>
          <div className={styles.txtWrapper}>
            {deviceType !== 'Mobile' && (
              <div className={styles.topWrapper}>
                <div className={styles.topTxtWrapper}>
                  <div className={styles.titleWrapper}>
                    <p className={styles.title}>{data.title}</p>
                    <p className={styles.title}>{data.title2}</p>
                  </div>
                </div>
                <div className={styles.iconWrapper}>
                  <a
                    href={data.mail}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={styles.iconCircle}
                    aria-label={`Open E-mail in a new window`}
                  >
                    <FaRegEnvelope className={styles.icon} />
                  </a>
                  <a
                    href={data.linkedin}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={styles.iconCircle}
                    aria-label={`Open Linkedin in a new window`}
                  >
                    <FaLinkedinIn className={styles.icon} />
                  </a>
                </div>
              </div>
            )}
            <p className={`${styles.introTxt} bodyTxt semiBold`}>
              {data.lecturerShortText}
            </p>
            <div className="paragraphWrapper">
              {data.lecturerLongerText.map((text, index) => (
                <p className="bodyTxt" key={index}>
                  {text}
                </p>
              ))}
            </div>
            <div className={styles.subjectWrapper}>
              <p className={`${styles.subjectsHeading} bodyTxt semiBold `}>
                {data.lecturerSubjectHeading}:
              </p>
              {data.lectureSubjects.map((subject, index) => (
                <div className={`${styles.subject} bodyTxt`} key={index}>
                  <IoCheckmarkCircleOutline className={styles.checkIcon} />
                  {subject}
                </div>
              ))}
            </div>
          </div>
        </div>
      </article>
      {!data.isLecturerReviews && data.id === 2 && (
        <div className={styles.border}></div>
      )}
      {data.isLecturerReviews && (
        <ReviewsSection
          text={data.lecturerReviewSecTxt}
          content={data.lecturerReviews}
          colored={true}
        />
      )}
    </div>
  );
}
