import React, { createContext, useContext, useEffect, useState } from 'react';

const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [shouldScroll, setShouldScroll] = useState(false);
  const [sectionId, setSectionId] = useState();
  const [userLanguage, setUserLanguage] = useState('sv');
  const [deviceType, setDeviceType] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reviewModalIsOpen, setReviewModalIsOpen] = useState(false);
  const [gdprModalIsOpen, setGdprModalIsOpen] = useState(false);

  useEffect(() => {
    if ('navigator' in window && 'language' in navigator) {
      if (
        navigator.language === 'sv' ||
        navigator.language === 'sv-SE' ||
        navigator.language === 'sv-FI' ||
        navigator.language === 'sv-NO' ||
        navigator.language === 'sv-DK' ||
        navigator.language === 'sv-FO' ||
        navigator.language === 'sv-AX'
      ) {
        setUserLanguage('sv');
      } else {
        setUserLanguage('other');
      }
    } else {
      setUserLanguage('sv');
    }
  }, []);

  useEffect(() => {
    detectDeviceType();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleResize = () => {
    detectDeviceType();
  };

  const detectDeviceType = () => {
    const width = window.innerWidth;
    if (width <= 767) {
      setDeviceType('Mobile');
    } else if (width <= 992) {
      setDeviceType('Tablet');
    } else {
      setDeviceType('Desktop');
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    setShouldScroll(false);
    if (section) {
      section.scrollIntoView(
        deviceType === 'Desktop' ? { behavior: 'smooth' } : { behavior: 'auto' }
      );
    }
  };

  return (
    <Context.Provider
      value={{
        userLanguage,
        setUserLanguage,
        deviceType,
        scrollToSection,
        shouldScroll,
        setShouldScroll,
        sectionId,
        setSectionId,
        modalIsOpen,
        setModalIsOpen,
        reviewModalIsOpen,
        setReviewModalIsOpen,
        gdprModalIsOpen,
        setGdprModalIsOpen,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const GlobalContext = () => {
  return useContext(Context);
};
