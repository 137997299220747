import React from 'react';
import styles from './ConsultantProject.module.css'

export default function ConsultantProject(project) {

    const data = { ...project.data };

    return (
        <article className={styles.wrapper}>
            <div className={styles.txtWrapper}>
                <h4 className='titleTxt'>{data.projectName}</h4>
                <p className={styles.company}>{data.projectCompany}</p>
                <p className='bodyTxt leftTxt'>{data.projectText}</p>
            </div>
        </article>
    )
}
