import React from 'react';
import styles from './ActivityCard.module.css';
import {
  GiMountainClimbing,
  GiHiking,
  GiSleepingBag,
  GiMountainRoad,
  GiSewingNeedle,
} from 'react-icons/gi';
import {
  MdOutlineDisabledByDefault,
  MdOutlineArrowForward,
  MdOutlineTempleBuddhist,
} from 'react-icons/md';
import { GlobalContext } from '../../../GlobalContext';

export default function ActivityCard(props) {
  const { setModalIsOpen } = GlobalContext();
  const data = { ...props.data };
  const image = require(`../../../images/${data.imgUrl}`);

  const IconRenderer = ({ iconName, className }) => {
    const iconMap = {
      hike: <GiHiking style={{ borderRadius: '50%' }} />,
      sleepingBag: <GiSleepingBag />,
      mountainRoad: <GiMountainRoad style={{ borderRadius: '50%' }} />,
      climb: <GiMountainClimbing style={{ borderRadius: '50%' }} />,
      temple: <MdOutlineTempleBuddhist style={{ fontSize: '2.1rem' }} />,
      needle: <GiSewingNeedle style={{ fontSize: '2rem' }} />,
    };

    const selectedIcon = iconMap[iconName] || <MdOutlineDisabledByDefault />;
    const iconWithClassName = React.cloneElement(selectedIcon, {
      className: className,
    });

    return <>{iconWithClassName}</>;
  };

  return (
    <article className={styles.cardWrapper}>
      <img src={image} alt={data.imgAlt} className={styles.img} />
      <div className={styles.txtWrapper}>
        <div>
          <div className={styles.iconCircle}>
            <IconRenderer className={styles.icon} iconName={data.icon} />
          </div>
          <h4
            className={`${styles.activityTitle} largeTxt mediumBold centerTxt`}
          >
            {data.title}
          </h4>
        </div>
        <MdOutlineArrowForward
          className={styles.iconArrow}
          onClick={() => {
            setModalIsOpen(true);
            props.handleModalContent(data);
          }}
        />
      </div>
    </article>
  );
}
