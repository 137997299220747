import React from 'react';
import styles from './MoodSection.module.css';
import { GlobalContext } from '../../GlobalContext';
import { moodSecTxt } from '../../Data/data';
import image from '../../images/logos/logo_transparent.png';

export default function MoodSection() {
  const { deviceType } = GlobalContext();

  return (
    <>
      {deviceType !== 'Mobile' && (
        <section className={styles.backgroundWrapper}>
          <section className={styles.sectionWrapper}>
            <div className={styles.cardWrapper}>
              <img src={image} alt="" className={styles.img} />
              <div className={styles.txtWrapper}>
                <p className="quoteTxtBold">{moodSecTxt.quoteTxt1}</p>
                <p className="quoteTxt quoteBy">{moodSecTxt.quoteTxt2}</p>
              </div>
            </div>
          </section>
        </section>
      )}
    </>
  );
}
