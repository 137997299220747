import React from 'react';
import styles from './LectureCard.module.css';
import { GlobalContext } from '../../../GlobalContext';

export default function LectureCard(props) {
  const { setModalIsOpen } = GlobalContext();
  const data = { ...props.data };
  const image = require(`../../../images/${data.imgUrl}`);

  return (
    <article
      className={
        props.styling === 'second' ? styles.cardWrapper2 : styles.cardWrapper
      }
      onClick={() => {
        setModalIsOpen(true);
        props.handleModalContent(data);
      }}
    >
      <img src={image} alt={data.imgAlt} className={styles.img} />
      <h4 className={`${styles.lectureTitle} largeTxt mediumBold whiteTxt`}>
        {data.title}
      </h4>
    </article>
  );
}
