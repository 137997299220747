import React, { useState } from 'react';
import Modal from 'react-modal';
import styles from './ActivitiesSection.module.css';
import { GlobalContext } from '../../GlobalContext';
import { activitiesSecTxt, activitiesSecTxtEng, activities, activitiesEng } from '../../Data/data';
import Heading from '../Heading/Heading';
import ActivityCard from './ActivityCard/ActivityCard';
import PopupModal from '../PopupModal/PopupModal';

export default function ActivitiesSection() {
    
    const { userLanguage, modalIsOpen, setModalIsOpen  } = GlobalContext();
    const [modalContent, setModalContent] = useState();
    const text = userLanguage === 'sv' ? activitiesSecTxt : activitiesSecTxtEng;
    const content = userLanguage === 'sv' ? activities : activitiesEng;

    const handleModalContent = (content) => {
        setModalContent(content)
    }

    return (
        <div className='background bgM1'>
            <section className={`${styles.sectionWrapper} sectionWrapper`}>
                <Heading position='center' data={text}/>
                <p className='bodyTxt centerTxt mediumTxt' style={{maxWidth: '1000px'}}>{text.introTxt}</p>
                <div className={styles.cardsWrapper}>
                    {content.map((activity, index) => (<ActivityCard key={index} data={activity}  handleModalContent={handleModalContent}/>))}
                </div>
            </section>
            <Modal         
                isOpen = {modalIsOpen}
                onRequestClose = {() => setModalIsOpen(false)}
                className = 'modalWrapper'
                overlayClassName = 'modalOverlay'>
                    <PopupModal modalType='activities' content={modalContent} />
            </Modal>
        </div>
    )
}
