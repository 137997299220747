import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import styles from './NewsSection.module.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { GlobalContext } from '../../GlobalContext';
import { newsSecTxt, newsSecTxtEng, news, newsEng } from '../../Data/data';
import NewsCard from './NewsCard/NewsCard';
import Heading from '../Heading/Heading';
import ButtonShowMore from '../ButtonShowMore/ButtonShowMore';
import PopupModal from '../PopupModal/PopupModal';

export default function NewsSection() {
  const { deviceType, userLanguage, modalIsOpen, setModalIsOpen } =
    GlobalContext();
  const text = userLanguage === 'sv' ? newsSecTxt : newsSecTxtEng;
  const content = userLanguage === 'sv' ? news : newsEng;
  const [modalContent, setModalContent] = useState();

  const handleModalContent = (content) => {
    setModalContent(content);
  };

  const MobileLayout = () => {
    const [displayedIndex, setDisplayedIndex] = useState(2);

    const handleNextClick = () => {
      setDisplayedIndex((prevIndex) => prevIndex + 2);
    };

    return (
      <div className={styles.cardWrapperM}>
        <div className={styles.cardContainerM}>
          {content.slice(0, displayedIndex).map((news, index) => (
            <NewsCard
              key={index}
              data={news}
              link={text.linkTxt}
              handleModalContent={handleModalContent}
            />
          ))}
        </div>
        {displayedIndex < content.length && (
          <ButtonShowMore value={text.btnTxt} onClick={handleNextClick} />
        )}
      </div>
    );
  };

  const DesktopLayout = () => {
    const [atLeftEdge, setAtLeftEdge] = useState(true);
    const [atRightEdge, setAtRightEdge] = useState(false);
    const sektionRef = useRef(null);

    const handleScroll = (event) => {
      const scrollLeft = event.target.scrollLeft;
      const clientWidth = event.target.clientWidth;
      const scrollWidth = event.target.scrollWidth - 1;
      const atLeft = scrollLeft === 0;
      const atRight = scrollLeft + clientWidth >= scrollWidth;

      setAtLeftEdge(atLeft);
      setAtRightEdge(atRight);
    };

    const scroll = (scrollOffset) => {
      sektionRef.current.scrollLeft += scrollOffset;
    };

    return (
      <div className={styles.cardWrapperD}>
        {!atLeftEdge && (
          <button
            aria-label="scroll left"
            className={styles.btn + ' ' + styles.btnLeft}
            onClick={() => scroll(-300)}
          >
            <FaChevronLeft />
          </button>
        )}
        {!atRightEdge && (
          <button
            aria-label="scroll right"
            className={styles.btn + ' ' + styles.btnRight}
            onClick={() => scroll(300)}
          >
            <FaChevronRight />
          </button>
        )}
        <div
          className={styles.cardContainerD}
          onScroll={handleScroll}
          ref={sektionRef}
        >
          {content.map((news, index) => (
            <NewsCard
              key={index}
              data={news}
              link={text.linkTxt}
              handleModalContent={handleModalContent}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <section className={`sectionWrapper`}>
      <Heading position="center" data={text} />
      {deviceType === 'Mobile' ? (
        <MobileLayout />
      ) : deviceType === 'Tablet' ? (
        <DesktopLayout />
      ) : (
        <DesktopLayout />
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modalWrapper"
        overlayClassName="modalOverlay"
      >
        <PopupModal modalType="news" content={modalContent} />
      </Modal>
    </section>
  );
}
