import React from 'react';
import styles from './Heading.module.css';
import logo from '../../images/logos/logo_transparentYellow.png';

export default function Heading(props) {
  const data = { ...props.data };

  return (
    <div
      className={styles.headingWrapper}
      style={{ alignItems: props.position }}
    >
      <div className={styles.topWrapper}>
        <div className={styles.logoWrapper}>
          <div className={styles.sectionLine}></div>
          <img src={logo} alt="" className={styles.logo} />
          <div className={styles.sectionLine}></div>
        </div>
        <h2 className={styles.sectionHeadingSmall}>
          {data.sectionHeadingSmall}
        </h2>
      </div>
      <h3
        className={
          props.color === 'white'
            ? styles.sectionHeadingLarge + ' ' + styles.white
            : styles.sectionHeadingLarge
        }
      >
        {data.sectionHeading}
      </h3>
    </div>
  );
}
