import React from 'react';
import styles from './FooterSection.module.css';
import { FaLinkedinIn, FaRegEnvelope, FaInstagram, FaRegCopyright } from 'react-icons/fa';
import { MdOutlineDisabledByDefault } from "react-icons/md";
import { GlobalContext } from '../../GlobalContext';
import { footerSecTxt, footerSecTxtEng } from '../../Data/data';

export default function FooterSection() {

    const { userLanguage } = GlobalContext();
    const text = userLanguage === 'sv' ? footerSecTxt : footerSecTxtEng;

    const SocialMedia = (media) => {

        const data = { ...media.data };

        const IconRenderer = ({ iconName, className }) => {
            const iconMap = {
                eMail: <FaRegEnvelope />,
                linkedin: <FaLinkedinIn />,
                instagram: <FaInstagram />
            };
    
            const selectedIcon = iconMap[iconName] || <MdOutlineDisabledByDefault />;
            const iconWithClassName = React.cloneElement(selectedIcon, {
                className: className,
            });

            return <>{iconWithClassName}</>;
        }

        return(
            <article className={styles.socialMedia}>
                <a href={data.link} target='_blank' rel='noreferrer noopener' className={styles.iconCircle} aria-label={`Open ${data.name} in a new window`}>
                    <IconRenderer className={styles.icon} iconName={data.icon} />
                </a> 
                <div className={styles.socialMediaTxtWrapper}>
                    <p className='bodyTxt semiBold whiteTxt'>{data.name}</p>
                    <a href={data.link} target='_blank' rel='noreferrer noopener' className='bodyTxt whiteTxt'>{data.details}</a>
                </div>     
            </article>
        )
    }

    const Info = (info) => {
        return (
            <div className={info.device === 'mobile' 
                                ? styles.infoWrapper 
                                : styles.infoWrapperDesktop}>
                <p className='bodyTxt semiBold whiteTxt'>{text.name}</p>
                <p className='bodyText whiteTxt'>{text.address}</p>
                <p className='bodyText whiteTxt'>{text.zipCode} {text.city}</p>
                <p className='bodyText whiteTxt'>{text.orgNr}</p>
            </div>
        )
    }

    return (
        <div className={`${styles.bgLogo} background bgD`}>
            <section className={styles.sectionWrapper}>
                <Info device={'mobile'}/>
                <div className={styles.socialMediaWrapper}>
                    {text.socialMedia.map((socialMedia, index) => <SocialMedia key={index} data={socialMedia}/>)}
                </div>
                <div className={styles.rightContent}>
                    <Info device={'desktop'} />
                    <div className={styles.copyrightWrapper}>
                        <p className='bodyText whiteTxt'>Copyright <FaRegCopyright /> Sherpify 2023.</p>
                        <p className='bodyText whiteTxt'>All rights reserved.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}
