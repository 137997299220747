import React from 'react';
import styles from './LecturerSection.module.css';
import { GlobalContext } from '../../GlobalContext';
import { people, peopleEng } from '../../Data/data';
import LecturerCard from './LecturerCard/LecturerCard';

export default function LecturerSection() {
    const { userLanguage } = GlobalContext();
    const content = userLanguage === 'sv' ? people : peopleEng;

    return (
        <section id='lecturers' className={`${styles.sectionWrapper} sectionWrapper`}>
                {content.map((lecturer) => (lecturer.isLecturer && <LecturerCard key={lecturer.id} data={lecturer} />))}
        </section>
    )
}


