import React from 'react';
import styles from './Button.module.css';

export default function Button({value, margin, align, isDisabled}) {
    
    return (
        <button 
            id='text'
            className={isDisabled ? styles.buttonDisabled : styles.button} 
            style={{marginTop: margin, alignSelf: align}}
            disabled={isDisabled}
        >
            {value}
        </button>
    )
}
