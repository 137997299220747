import React, { useState } from 'react';
import styles from './PopupModal.module.css';
import { IoCloseOutline } from 'react-icons/io5';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { GlobalContext } from '../../GlobalContext';
import ButtonContact from '../ButtonContact/ButtonContact';

export default function PopupModal(props) {
  const { setModalIsOpen } = GlobalContext();
  const { modalType, content } = props;
  const data = { ...content };
  const image = content.imgUrl && require(`../../images/${content.imgUrl}`);

  const NewsModal = () => {
    return (
      <article className={styles.contentWrapper}>
        <img src={image} alt={data.imgAlt} className={styles.img} />
        <p className="bodyTxt italicTxt">
          {data.month}, {data.year}
        </p>
        <p className="bodyTxt semiBold">{data.intro}</p>
        <div className="paragraphWrapper">
          {data.text.map((paragraph, index) => (
            <p className="bodyTxt" key={index}>
              {paragraph}
            </p>
          ))}
        </div>
      </article>
    );
  };

  //PREPAIRED FOR PRICE
  const LectureModal = () => {
    return (
      <article className={styles.contentWrapper}>
        <img src={image} alt={data.imgAlt} className={styles.img} />
        <p className={`${styles.title} largeTxt`}>{data.title}</p>
        {data.intro && (
          <p className="bodyTxt semiBold mediumTxt">{data.intro}</p>
        )}
        <div className="paragraphWrapper">
          {data.text.map((paragraph, index) => (
            <p className="bodyTxt" key={index}>
              {paragraph}
            </p>
          ))}
        </div>
        {/* <p className='bodyTxt' style={{marginTop: '1rem'}}>{data.price}</p> */}
        <ButtonContact value={data.btnTxt} align="center" margin="1.5rem" />
      </article>
    );
  };

  //PREPAIRED FOR PRICE
  const ActivityModal = () => {
    const Gallery = () => {
      const [currentImageIndex, setCurrentImageIndex] = useState(0);
      const currentImage = content.imgList[currentImageIndex];

      const handleThumbnailClick = (index) => {
        setCurrentImageIndex(index);
      };

      const handleNextButtonClick = () => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % content.imgList.length
        );
      };

      const handlePrevButtonClick = () => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === 0 ? content.imgList.length - 1 : prevIndex - 1
        );
      };

      return (
        <div className={styles.galleryWrapper}>
          <div className={styles.displayWrapper}>
            <img
              src={require(`../../images/${currentImage}`)}
              alt="Display Area"
              className={styles.displayImg}
            />
            <button
              onClick={handlePrevButtonClick}
              className={styles.btn + ' ' + styles.btnLeft}
            >
              <FaChevronLeft />
            </button>
            <button
              onClick={handleNextButtonClick}
              className={styles.btn + ' ' + styles.btnRight}
            >
              <FaChevronRight />
            </button>
          </div>
          <div className={styles.thumbnailWrapper}>
            {content.imgList.map((image, index) => (
              <img
                key={index}
                src={require(`../../images/${image}`)}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => handleThumbnailClick(index)}
                className={
                  index === currentImageIndex
                    ? styles.thumbnail + ' ' + styles.thumbnailActive
                    : styles.thumbnail
                }
              />
            ))}
          </div>
        </div>
      );
    };

    return (
      <article className={styles.contentWrapper}>
        <Gallery />
        <p className={`${styles.title} largeTxt`}>{data.title}</p>
        <p className="bodyTxt mediumTxt semiBold">{data.intro}</p>
        <div className="paragraphWrapper">
          {data.text.map((paragraph, index) => (
            <p className="bodyTxt" key={index}>
              {paragraph}
            </p>
          ))}
        </div>
        {/* <p className='bodyTxt' style={{marginTop: '1rem'}}>{data.price}</p> */}
        <ButtonContact value={data.btnTxt} align="center" margin="1.5rem" />
      </article>
    );
  };

  return (
    <section className={styles.modalWrapper}>
      <IoCloseOutline
        className={styles.icon}
        onClick={() => setModalIsOpen(false)}
      />
      {modalType === 'news' ? (
        <NewsModal />
      ) : modalType === 'lectures' ? (
        <LectureModal />
      ) : modalType === 'activities' ? (
        <ActivityModal />
      ) : (
        <div>innehåll saknas</div>
      )}
    </section>
  );
}
