import React from 'react';
import styles from './ReviewModal.module.css';
import { BiSolidQuoteAltRight } from "react-icons/bi";
import { IoCloseOutline } from "react-icons/io5";
import { GlobalContext } from '../../GlobalContext';

export default function ReviewModal(props) {
    const { setReviewModalIsOpen } = GlobalContext();
    const data = {...props.content}
    
    return (
        <section className={styles.modalWrapper}>
            <IoCloseOutline className={styles.icon} onClick={() => setReviewModalIsOpen(false)}/>
            <article className={styles.contentWrapper}>   
                <BiSolidQuoteAltRight className={styles.quoteIcon}/>
                <div className={styles.topWrapper}>
                    <div>
                        <p className='bodyTxt mediumTxt semiBold'>{data.name}</p>
                        <p className='bodyTxt'>{data.role}</p>
                    </div>
                    <p className='bodyTxt italicTxt'>{data.date}</p>
                </div>
                <div className='paragraphWrapper'>
                    {data.review.map((paragraph, index) => (<p className='bodyTxt' key={index}>{paragraph}</p>))}
                </div>
            </article>
        </section>
    )
}
