import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './HeaderSection.module.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import { SE, GB } from 'country-flag-icons/react/3x2';
import { GlobalContext } from '../../GlobalContext';
import { headerSecTxt, headerSecTxtEng } from '../../Data/data';
import logoImg from '../../images/logos/logo_transparent.png';
import logo from '../../images/logos/logo_withText.png';

export default function HeaderSection() {
  const {
    deviceType,
    userLanguage,
    setUserLanguage,
    scrollToSection,
    setShouldScroll,
    setSectionId,
  } = GlobalContext();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const text = userLanguage === 'sv' ? headerSecTxt : headerSecTxtEng;

  const toggleLanguage = (event, language) => {
    event.stopPropagation();
    setUserLanguage(language);
  };

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const displayLanguageIcon = () => {
    return userLanguage !== 'sv' ? (
      <div
        className={styles.selectLangWrapper}
        onClick={(event) => toggleLanguage(event, 'sv')}
      >
        <SE title="Svenska" className="flagIcon" style={{ height: '25px' }} />
        {deviceType !== 'Desktop' && <p>Svenska</p>}
      </div>
    ) : (
      <div
        className={styles.selectLangWrapper}
        onClick={(event) => toggleLanguage(event, 'en')}
      >
        <GB title="English" className="flagIcon" style={{ height: '25px' }} />
        {deviceType !== 'Desktop' && <p>English</p>}
      </div>
    );
  };

  const DesktopLayout = () => {
    return (
      <header className={styles.layoutWrapperD}>
        <Link
          className={styles.homelink}
          to="/"
          aria-label="Navigate to homepage"
          onClick={() => {
            setShouldScroll(true);
            setSectionId('landing');
          }}
        >
          <div className={styles.imgWrapper}>
            <img
              src={logo}
              alt="Företagets logotyp föreställande en ändlös knut"
              className={styles.img}
            />
          </div>
        </Link>
        <div className={styles.leftSideWrapper}>
          <nav className={styles.linkWrapper}>
            <Link
              className={styles.link}
              to="/"
              onClick={() => {
                setShouldScroll(true);
                setSectionId('landing');
              }}
            >
              {text.home}
            </Link>
            <Link
              className={styles.link}
              to="/"
              onClick={() => {
                setShouldScroll(true);
                setSectionId('areas');
              }}
            >
              {text.services}
            </Link>
            <Link
              className={styles.link}
              to="/"
              onClick={() => {
                setShouldScroll(true);
                setSectionId('background');
              }}
            >
              {text.about}
            </Link>
            <Link
              className={styles.link + ' ' + styles.button}
              to=""
              onClick={() => {
                setShouldScroll(true);
                setSectionId('contact');
              }}
            >
              {text.contact}
            </Link>
          </nav>
          {displayLanguageIcon()}
        </div>
      </header>
    );
  };

  const MobileLayout = () => {
    return (
      <header className={styles.layoutWrapperM}>
        <Link
          className={styles.homelink}
          to="/"
          onClick={() => {
            setShouldScroll(true);
            setSectionId('landing');
          }}
        >
          <div className={styles.imgWrapper}>
            <img
              src={logoImg}
              alt="Företagets logotyp föreställande en ändlös knut"
              className={styles.img}
            />
          </div>
        </Link>
        {!menuIsOpen ? (
          <FaBars className={styles.icon} onClick={toggleMenu} />
        ) : (
          <FaTimes className={styles.icon} onClick={toggleMenu} />
        )}
        {menuIsOpen && <MobileMenu />}
      </header>
    );
  };

  const MobileMenu = () => {
    return (
      <div className={styles.mobileMenuBackground} onClick={toggleMenu}>
        <div className={styles.mobileMenuContentWrapper}>
          <nav className={styles.mobileLinkWrapper}>
            <Link
              className={styles.link}
              to="/"
              onClick={() => {
                setShouldScroll(true);
                setSectionId('landing');
                toggleMenu();
              }}
            >
              {text.home}
            </Link>
            <Link
              className={styles.link}
              to="/"
              onClick={() => {
                setShouldScroll(true);
                setSectionId('areas');
                toggleMenu();
              }}
            >
              {text.services}
            </Link>
            <Link
              className={styles.link + ' ' + styles.sublink}
              to="konsulting"
              onClick={() => {
                scrollToSection('intro');
                toggleMenu();
              }}
            >
              {text.consulting}
            </Link>
            <Link
              className={styles.link + ' ' + styles.sublink}
              to="forelasningar"
              onClick={() => {
                scrollToSection('intro');
                toggleMenu();
              }}
            >
              {text.lectures}
            </Link>
            <Link
              className={styles.link + ' ' + styles.sublink}
              to="aktiviteter"
              onClick={() => {
                scrollToSection('intro');
                toggleMenu();
              }}
            >
              {text.activities}
            </Link>
            <Link
              className={styles.link}
              to="/"
              onClick={() => {
                setShouldScroll(true);
                setSectionId('background');
                toggleMenu();
              }}
            >
              {text.about}
            </Link>
            <Link
              className={styles.link}
              to="/"
              onClick={() => {
                setShouldScroll(true);
                setSectionId('contact');
                toggleMenu();
              }}
            >
              {text.contact}
            </Link>
          </nav>
          {displayLanguageIcon()}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.background}></div>
      {deviceType === 'Mobile' ? (
        <MobileLayout />
      ) : deviceType === 'Tablet' ? (
        <MobileLayout />
      ) : (
        <DesktopLayout />
      )}
    </>
  );
}
