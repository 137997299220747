/*********** HOME PAGE ***********/
/* INTRO - heading/page intro */
export const homeIntroTxt = {
  imgUrl: 'home_intro2.png',
  imgAlt: 'Helene och Pemba på toppen av ett berg med flaggspel i bakgrunden',
  sectionHeadingSmall: '',
  sectionHeading: 'Passion är vår drivkraft',
  bodyTxt: [
    'När en svensk konsult med smak för äventyr träffar den Nepalesiske toppguiden som lever äventyret varje dag slår det gnistor och magi skapas.',
    'Vi är Sherpify – det lilla företaget med passion för stora äventyr. Vi driver team framåt genom konsulting, föreläsningar och aktiviteter utöver det vanliga.',
  ],
  quoteTxt1:
    '“Working hard for something we don´t care about is called stress. Working hard for something we love is called passion”',
  quoteTxt2: '- Simon Sinek',
};
export const homeIntroTxtEng = {
  imgUrl: 'home_intro2.png',
  imgAlt:
    'Helene and Pemba at the top of a mountain with prayer flags in the background.',
  sectionHeadingSmall: '',
  sectionHeading: 'Driven by passion ',
  bodyTxt: [
    'When a Swedish consultant with a taste for adventure meets an adventurous Nepalese mountain guide, sparks fly, and magic is created.',
    'We are Sherpify – a small company with a big passion for adventures. We lead and inspire teams through consulting, lectures, and team activities with an edge.',
  ],
  quoteTxt1:
    '“Working hard for something we don´t care about is called stress. Working hard for something we love is called passion”',
  quoteTxt2: '- Simon Sinek',
};
/* AREAS - heading */
export const areasSecTxt = {
  sectionHeadingSmall: 'Utbud',
  sectionHeading: 'Sherpify utmanar och utbildar',
  bodyTxt:
    'På Sherpify älskar vi utmaningar. Företagets filosofi bygger på äventyrslust och vi tror på att utmana normer och att göra saker på vårt sätt. För oss är ingenting omöjligt och det är vår grund när vi håller föreläsningar, gruppaktiviteter och bedriver projekt.',
  btnTxt: 'Kontakta oss',
};
export const areasSecTxtEng = {
  sectionHeadingSmall: 'Services',
  sectionHeading: 'We challenge you ',
  bodyTxt:
    "At Sherpify, we love challenges. The company's philosophy is built on the spirit of adventure, and we believe in challenging status quo and doing things our way. For us, nothing is impossible, and that forms the base for our lectures, our group activities, and us as consultants",
  btnTxt: 'Contact us',
};
/* AREAS - data */
export const areas = [
  {
    title: 'Konsulttjänster',
    text: 'Med lång erfarenhet som senior projektledare, hittar Helene nya vägar att driva komplexa projekt i mål. Helenes styrka ligger i att alltid se den bästa lösningen för kunden och tillsammans med sitt projektteam ta de beslut som behövs för att nå hela vägen.',
    link: 'konsulting',
    icon: 'consulting',
  },
  {
    title: 'Föreläsningar',
    text: 'Ledarskap i extrema miljöer är en utmaning som kräver stort fokus och tillit från gruppen. I sina föreläsningar använder Pemba sin erfarenhet som bergsguide på Mount Everest och ger perspektiv på ett ledarskap, risk- och problemhantering i dagens företag.',
    link: 'forelasningar',
    icon: 'lectures',
  },
  {
    title: 'Teamaktiviteter',
    text: 'Sherpify har ett unikt koncept för företag som vill utmana och utveckla sitt team genom aktiviteter utöver det vanliga.  Vårt utbud bygger på problemlösning i miljöer som är långt utanför gruppens bekvämlighetszon.',
    link: 'aktiviteter',
    icon: 'activities',
  },
];
export const areasEng = [
  {
    title: 'Consulting services',
    text: "With an extensive experience as a senior project manager, Helene keeps finding new ways to successfully manage complex projects from start to completion. Helene's strength lies in always finding the best way forward for the client and, together with her project team, making the necessary decisions to reach the finish line.",
    link: 'konsulting',
    icon: 'consulting',
  },
  {
    title: 'Lectures',
    text: 'Leadership in extreme environments is a challenge that demands immense focus and trust from the team. In his lectures, Pemba uses from his experience as a mountain guide on Mount Everest to provide new perspectives of leadership, risk management, and problem-solving.',
    link: 'forelasningar',
    icon: 'lectures',
  },
  {
    title: 'Team activities',
    text: "Sherpify offers a unique concept for companies who are looking to challenge and develop their teams through activities with an edge. Our offerings are based on problem-solving in environments that pushes the group's comfort zone.",
    link: 'aktiviteter',
    icon: 'activities',
  },
];
/* MOOD  */
export const moodSecTxt = {
  quoteTxt1: 'You don´t hire for skills; you hire for attitude',
  quoteTxt2: '- Simon Sinek',
};
/* NEWS - heading */
export const newsSecTxt = {
  sectionHeadingSmall: 'Nyheter',
  sectionHeading: 'På gång hos Sherpify',
  btnTxt: 'Visa fler',
  linkTxt: 'Läs mer',
};
export const newsSecTxtEng = {
  sectionHeadingSmall: 'News',
  sectionHeading: "What's happening at Sherpify",
  btnTxt: 'Show more',
  linkTxt: 'Read more',
};
/* NEWS - data */
export const news = [
  // {
  //   month: 'Oktober',
  //   year: '2023',
  //   imgUrl: 'news/news5_birthday.png',
  //   imgAlt:'',
  //   intro: 'Vi fyller år!',
  //   text: [
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  //   ],
  // },
  {
    month: 'September',
    year: '2023',
    imgUrl: 'news/news4_launch.png',
    imgAlt:
      'Visar hur företagets webbsida ser ut på telefon, surfplatta och dator.',
    intro: 'Lansering av nya webbsidan',
    text: [
      'Idag lanserar vi vår nya hemsida som vi är otroligt stolta över! Nu är den äntligen klar och den är fullproppad med spännande nyheter för att du enkelt ska kunna läsa mer om Sherpify.',
      'Här finns information om våra aktiviteter och föreläsningar och du hittar också massor av fantastiska foton från våra olika äventyr runt om i världen.',
      'Skicka gärna ett mejl till oss och säg hej!',
    ],
  },
  {
    month: 'September',
    year: '2023',
    imgUrl: 'news/news3_new_areas.png',
    imgAlt: 'Pemba uppe på ett berg med utsträckta armar',
    intro: 'Sherpify växer inom nya områden.',
    text: [
      'Det händer mycket inom Sherpify just nu! Vi har gått från att vara ett enmansbolag med inriktning på konsulttjänster till att vara flera anställda och med nya spännande projekt på gång.',
      'Vi erbjuder teamaktiviteter som tar äventyret till svenska företag och vi håller också spännande föreläsningar om ledarskap i extrema miljöer. Vi vill att du och ditt team genom våra aktiviteter stärks som grupp för att sen arbeta bättre tillsammans. Våra guidade turer kan du uppleva både i Kolmårdens vildmark men även i storslagen bergsmiljö - i Sverige eller Nepal!',
      'På Sherpify har vi en passion för ledarskap och äventyr som vi vill sprida vidare. Hör av dig till oss så berättar vi mer!',
    ],
  },
  {
    month: 'Februari',
    year: '2023',
    imgUrl: 'news/news2_cleanup.jpg',
    imgAlt:
      'Helene överlämnar projektet till lokala myndigheter och tar emot ett uppskattningsbrev för sitt arbete',
    intro: 'Överlämning av vårt CleanUp projekt',
    text: [
      'I lördags överlämnade Pemba och jag över ett CleanUp projekt till de lokala myndigheterna här i Pokhara Nepal efter att ha samlat in drygt 2,5 ton skräp på 14 dagar.',
      'För drygt två veckor sen kom vi till floden i stan för att bada i de varma vädret men möttes av oerhört mycket skräp överallt. Vi beslutade oss för att börja städa, utan att riktigt inse vad vi gav oss in på. När vi köpt säckar och handskar började vi plocka. Det var så mycket plast, gamla kläder, cementsäckar, plastmattor, plastpåsar överallt. De små öarna i floden visade sig vara uppbyggda nästan helt av plast och tyger. Vi la upp allt skräp på land först så att det fick en chans att torka innan vi packade i säckar.',
      'Vi tänkte att om vi fortsätter i några dagar så kommer fler snart att vilja hjälpa till. Men ingen kom. Istället ifrågasatte flera varför vi städade. En kvinna menade att det hela var slöseri med tid, att skräpet kommer försvinna av sig själv när monsunen kommer och sköljer bort allt. Tro mig, de där öarna hade inte skapats på mindre än ett år. Men folk förstår inte. De saknar information om plastens påverkan på naturen, att plast inte magiskt försvinner av sig själv bara för att det åkt med floden.',
      'Efter en veckas konstant arbete fick en journalist höra tals om vårt arbete och bad om att få hjälpa till. Han tog med några andra kollegor och vänner. Det ledde till ett möte med stadschefens sekreterare. Dagen efter ett möte med distriktschefen för området där städprojektet pågår och dagen därefter ett möte med stadschefen själv.',
      'I lördags var vi ca 60 personer som plockade skräp tillsammas vid floden och en överlämningsceremoni hölls där distriktschefen och stadschefen lovade att ha hand om allt insamlat skräp, samt fortsätt arbetet vi startat. De tackade för att vi öppnat deras ögon för vad som behöver göras, de kände sig även lite generade att två turister i staden städat upp deras skräp. Nu är en ungdomsförening, två kvinnoföreningar och en förening i området engagerade i projektet vi kallar CleanUp Pokhara. Jag känner mig stolt!',
      'En lärdom är att fortsätta arbeta för det man tror på, även om ingen annan förstår. Förändring tar tid. Beteendeförändring tar ännu längre tid. För att kunna genomföra en förändring så behöver man ha ledarna med sig. De behöver förstå och ha viljan och mandatet. Först då följer andra efter. Det vi gjort har skapat inspiration och motivation hos dem med makten att göra något åt saken. Det är deras tur att fortsätta visa vägen.',
    ],
  },
  {
    month: 'Januari',
    year: '2022',
    imgUrl: 'news/news1_school.jpg',
    imgAlt: 'Barn som sitter i skolbänkar',
    intro: 'Det borde vara alla barns rätt att gå i skolan',
    text: [
      'Det borde vara alla barns rätt att få gå i skolan och drömma om vad de vill bli när de blir stora. Men den rätten är inte lika självklar överallt. I den nepalesiska bergsbyn Patle på 2400 meters höjd finns en skola som byggdes 2016 av en eldsjäl som själv aldrig fick chansen att gå i skola och som brinner för att ge barn den chans till utbildning han aldrig fick.',
      'På skolan finns 8 lärare varav 4 betalas av donationer. Under pandemin har det varit svårt att samla in pengar för dessa lärare. Därför stöttar Sherpify från och med i år med att betala årslönen för en lärare så att den icke vinstdrivande organisationen Ascent Aid Nepal och grundaren Pemba Sherpa kan fortsätta sitt fantastiska arbete.',
      'Alla barn har rätt till en utbildning. Alla barn har rätt att få bli vad dom drömmer om. Oavsett var du är född!',
    ],
  },
  // {
  //     month: 'Månad',
  //     year: '0000',
  //     imgUrl: 'no_image_3_2.png',
  //     imgAlt:'',
  //     intro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod   tempor incididunt ut labore et dolore. ',
  //     text: [
  //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  //     ]
  // },
];
export const newsEng = [
  // {
  //   month: 'October',
  //   year: '2023',
  //   imgUrl: 'news/news5_birthday.png',
  //   imgAlt:'',
  //   intro: 'It´s our birthday',
  //   text: [
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  //   ],
  // },
  {
    month: 'September',
    year: '2023',
    imgUrl: 'news/news4_launch.png',
    imgAlt:
      "Showing how the company's website looks on a phone, tablet, and computer.",
    intro: 'Sherpify has a new website!',
    text: [
      "Today, we are launching our new website, and we are incredibly proud of it! It's finally complete and packed with exciting updates for you to easily learn more about Sherpify.",
      "Here, you'll find information about our us consultants and our new team activities and lectures, and you'll also discover plenty of amazing photos from our various adventures around the world.",
      'Feel free to send us an email and say hello!',
    ],
  },
  {
    month: 'September',
    year: '2023',
    imgUrl: 'news/news3_new_areas.png',
    imgAlt: 'Pemba on top of a mountain with arms outstretched.',
    intro: 'Sherpify is expanding into new areas.',
    text: [
      "A lot is happening at Sherpify right now! We've gone from being a one-person company focused on consulting services to having multiple employees and exciting new projects going on.",
      'We offer team activities that bring adventure to Swedish companies, and we also conduct exciting lectures on leadership in extreme environments. We aim for you and your team to strengthen as a group through our activities, enabling you to work better together. Our guided activities can be experienced both in the wilderness of Kolmården and in magnificent mountain settings - in Sweden or Nepal!',
      "At Sherpify, we have a passion for leadership and adventure that we want to share. Get in touch with us, and we'll tell you more!",
    ],
  },
  {
    month: 'February',
    year: '2023',
    imgUrl: 'news/news2_cleanup.jpg',
    imgAlt:
      'Helen hands over the project to the local authorities and receives an appreciation letter for her work',
    intro: 'Handover of our CleanUp project',
    text: [
      'Last Saturday, Pemba and I handed over a CleanUp project to the local authorities here in Pokhara, Nepal, after collecting over 2.5 tons of garbage in 14 days.',
      'Over two weeks ago, we came to the river in town to swim, but we were met with a large amount of trash everywhere. We decided to start cleaning up, without really realizing what we were getting into. After buying bags and gloves, we began picking up trash. There was so much plastic, old clothes, cement bags, plastic mats, plastic bags everywhere. The small islands in the river turned out to be constructed almost entirely of plastics and fabrics. We initially piled up all the trash on the shore to let it dry before we packed it into bags.',
      "We thought that if we continued for a few days, more people would soon want to help. But no one came. Instead, several questioned why we were cleaning up at all. One woman argued that it was a waste of time, that the trash would disappear on its own when the monsoon came and washed everything away. Trust me, those islands hadn't been created in less than a year. But people don't understand. They lack information about the impact of plastic on nature, that plastic doesn't magically disappear on its own just because it has been carried away by the river.",
      "After a week of continuous work, a journalist heard about our efforts and asked to join. He brought along some colleagues and friends. This led to a meeting with the city mayor's secretary. The day after, we had a meeting with the district chief of the area where the cleanup project was taking place, and the following day, we met with the city mayor himself.",
      "Last Saturday, about 60 people gathered to clean up trash along the river, and a handover ceremony was held where the district chief and the city mayor pledged to take care of all the collected waste and continue the work we had started. They thanked us for opening their eyes to what needs to be done, and they felt a bit embarrassed that two tourists in the city had cleaned up their trash. Now, a youth association, two women's associations, and a local organization in the area are involved in the project we call CleanUp Pokhara. I feel proud!",
      "One lesson learned is to always continue working for what you believe in, even if no one else understands. Change takes time. Changing behavior takes even longer. To bring about change, you need to have leaders on board. They need to understand and have the will and authority. Only then will others follow. What we have done has created inspiration and motivation among those in power to do something about it. It's their turn to continue showing the way.",
    ],
  },
  {
    month: 'January',
    year: '2022',
    imgUrl: 'news/news1_school.jpg',
    imgAlt: 'Children sitting in school desks',
    intro: 'Every child’s right to attend school.',
    text: [
      'It should be the right of every child to attend school and dream about what they want to become when they grow up. But that right is not equally guaranteed everywhere. In the Nepalese mountain village of Patle, at an altitude of 2400 meters, there is a school that was built in 2016 by a passionate individual who never had the opportunity to attend school himself. He is dedicated to providing children with the education he never had the chance to receive',
      "There are 8 teachers working at the school, 4 of them being funded through donations. During the pandemic, it has been challenging to raise funds for these teachers. That's why, starting this year, Sherpify is supporting by covering the annual salary of one teacher, allowing the non-profit organization Ascent Aid Nepal and its founder Pemba Sherpa to continue their fantastic work.",
      'Every child has the right to an education. Every child has the right to become what they dream of, regardless of where they are born!',
    ],
  },
];
/* BACKGROUND - heading */
export const backgroundSecTxt = {
  sectionHeadingSmall: 'Om oss',
  sectionHeading: 'Hur det började',
  imgUrl: 'home_background.jpg',
  imgAlt: 'Helene och Pemba med en stad i bakgrunden',
  text: [
    '2019 kom Helene till Nepal för att bestiga Island Peak på 6165 meters höjd. Pemba var hennes bergsguide och där på hisnande höjder startade äventyret som idag är Sherpify.',
    'Mitt under pandemin 2020 fick Helenes längtan efter frihet henne att hoppa av sin fasta anställning och starta Sherpify. Helenes mål var att bygga upp sitt bolag inom senior projektledning och driva det på sitt eget sätt från den plats hon befinner sig.',
    '2023 anställdes hennes man Pemba. Helene såg stor potential i hans unika bakgrund och långa erfarenhet som bergsguide i Himalaya med åtta toppbestigningar av Mount Everest i bagaget. Genom sina föreläsningar har Pemba redan inspirerat många med sin passion för äventyr och ledarskap i extrema miljöer.',
    'Deras passion för äventyr är grunden i Sherpify. Tillsammans driver de idag ett unikt koncept som förenar senior konsulting, med gruppaktiviteter och föreläsningar.',
  ],
};
export const backgroundSecTxtEng = {
  sectionHeadingSmall: 'About us',
  sectionHeading: 'How it all began',
  imgUrl: 'home_background.jpg',
  imgAlt: 'Helene and Pemba with a city in the background',
  text: [
    'In 2019, Helene came to Nepal to climb Island Peak at an altitude of 6,165 meters. Pemba was her mountain guide, and it was at those breathtaking heights that the adventure that is now Sherpify began.',
    "During the pandemic in 2020, Helene's longing for freedom led her to resign from her regular job and start her own business. The goal was to build a company focused on senior project management and run it from wherever she found herself.",
    'In 2023, her husband Pemba was hired. Helene saw great potential in his unique background and extensive experience as a Himalayan mountain guide with eight successful Mount Everest summits under his belt. Through his lectures, Pemba has already inspired many with his passion for adventure and leadership in extreme environments.',
    'Their passion for adventure is the foundation of Sherpify. Together, they now run a unique concept that combines senior consulting with group activities and lectures.',
  ],
};
/* WEWANTMORE - heading */
export const weWantMoreSecTxt = {
  sectionHeadingSmall: 'Om oss',
  sectionHeading: 'Vi vill mer',
  imgUrl1: 'wewantmore4.png',
  imgAlt1: 'Barn sitter och skriver i sina skolbänkar',
  imgUrl2: 'wewantmore3.jpg',
  imgAlt2: 'Skolan och skolgården',
  introTxt:
    'För Sherpify är det viktigt att ge tillbaka, det är en del av vårt DNA.',
  bodyTxt: [
    'När Pemba växte upp i den lilla byn Patle i Nepal fanns ingen skola värd namnet, inga vägar eller avlopp. Sherpify arbetar tillsammans med Ascent Aid Nepal, en välgörenhetsorganisation som arbetar för att minska fattigdomen och öka utbildningen i Nepal.',
    'Ascent Aid Nepal startades av Pemba och drivs idag framåt av frivilliga personer som vill göra skillnad i Nepal.',
    '2016 började organisationen bygga en skola i Patle efter att en massiv jordbävning förstört den gamla skolan som fanns i byn.  Idag finns faciliteter som skolmatsal och kök, toaletter och två skolbyggnader för cirka 100 elever. Ascent Aid Nepal står dessutom för all mat och fyra av åtta lärarlöner varje månad, för att alla barn i byn ska få möjlighet att gå i en riktigt bra skola. Sherpify bidrar stolt varje år genom att skänka en del av vinsten för att betala årslönen för en av lärarna.',
  ],
  bodyTxt2: [],
  logoUrl: 'logo_aan_originalSmall.jpg',
  supportTxt1: 'Besök ',
  supportLinkTxt: 'ascentaidnepal.org',
  supportTxt2: ' för att läsa mer om deras arbete',
};
export const weWantMoreSecTxtEng = {
  sectionHeadingSmall: 'About us',
  sectionHeading: 'We want more',
  imgUrl1: 'wewantmore4.png',
  imgAlt1: 'Children are sitting and writing at their desks in school',
  imgUrl2: 'wewantmore3.jpg',
  imgAlt2: 'The school and the schoolyard',
  introTxt: "For us, giving back is important; it's part of our DNA.",
  bodyTxt: [
    'When Pemba grew up in the small village of Patle in Nepal, there was no proper school, no roads, or sewage systems. Sherpify collaborates with Ascent Aid Nepal, a charity organization working to reduce poverty and improve education in Nepal.',
    'Ascent Aid Nepal was founded by Pemba and is running by volunteers who want to make a difference in Nepal.',
    'In 2016, the organization began building a school in Patle after a massive earthquake had destroyed the old school in the village. Today, there are facilities such as a school kitchen, toilets, and two school buildings housing approximately 100 students. Ascent Aid Nepal also covers all the meals and four out of eight teacher salaries every month to ensure that all children in the village have the opportunity to attend a high quality school. Sherpify proudly contributes each year by donating a portion of its profits to cover the annual salary of one of the teachers.',
  ],
  bodyTxt2: [],
  logoUrl: 'logo_aan_originalSmall.jpg',
  supportTxt1: 'Visit ',
  supportLinkTxt: 'ascentaidnepal.org',
  supportTxt2: ' to read more about their work',
};

/* ABOUT - heading */
export const aboutSecTxt = {
  sectionHeadingSmall: 'Om oss',
  sectionHeading: 'Vi är Sherpify',
};
export const aboutSecTxtEng = {
  sectionHeadingSmall: 'About us',
  sectionHeading: 'We are Sherpify',
};

/*********** CONSULTING PAGE ***********/
/* INTRO - heading/page intro */
export const consultingIntroTxt = {
  imgUrl: 'consulting_intro.jpg',
  imgAlt: 'Helene på toppen av ett snötäckt berg',
  sectionHeadingSmall: '',
  sectionHeading: 'Konsult som utmanar',
  bodyTxt: [
    'En driven konsult och passionerad projektledare - Helene vågar ta sin egen väg för att hitta smarta och utvecklande lösningar i de projekt hon driver. En konsult med lång erfarenhet som tillsammans med team och kunden leder projekt från analys till implementering.',
    'Helene startade Sherpify 2020, och driver nu sin konsultbyrå från den plats i världen hon befinner sig.',
  ],
};
export const consultingIntroTxtEng = {
  imgUrl: 'consulting_intro.jpg',
  imgAlt: 'Helene at the summit of a snow-covered mountain.',
  sectionHeadingSmall: '',
  sectionHeading: 'Consultant who challenges',
  bodyTxt: [
    'A driven consultant and passionate project manager - Helene dares to take her own path in order to find smart solutions for the projects she leads. With an extensive experience, she leads projects from requirement analysis to implementation',
    'Helene founded Sherpify in 2020 and now runs her consulting business from wherever she is in the world.',
  ],
};

/*********** LECTURES PAGE ***********/
/* INTRO - heading/page intro */
export const lecturesIntroTxt = {
  imgUrl: 'lectures_intro2.jpg',
  imgAlt: 'I förgrunden en blommande äng, i bakgrunden ses ett snötäckt berg',
  sectionHeadingSmall: '',
  sectionHeading: 'Sherpify föreläser om äventyr och ledarskap',
  bodyTxt: [
    'Att leda i extrema miljöer är en utmaning som kräver stort mod och fokus från ledaren. Pembas erfarenhet som toppguide på Mount Everest är unik och i sina föreläsningar berättar han om att ta blixtsnabba beslut i utsatta situationer och hur han bygger tillit i sina team.',
    'Helenes långa erfarenhet som senior projektledare och digital nomad inspirerar och utmanar i en föreläsning om att våga gå mot strömmen för att skapa sin egen lycka.',
  ],
};
export const lecturesIntroTxtEng = {
  imgUrl: 'lectures_intro2.jpg',
  imgAlt:
    'In the foreground, a blooming meadow; in the background, a snow-covered mountain.',
  sectionHeadingSmall: '',
  sectionHeading: 'Sherpify lectures about adventure and leadership',
  bodyTxt: [
    "Leading in extreme environments is a challenge that demands great courage and focus from the leader. Pemba's experience as a mountain guide on Mount Everest is unique, and in his lectures, he shares insights about making rapid decisions in precarious situations and how he builds trust within his teams.",
    "Helene's extensive experience as a senior project manager and digital nomad inspires and challenges in a lecture about daring to go against the current to create one's own happiness.",
  ],
};
/* LECTURES - heading */
export const lecturesSecTxt = {
  sectionHeadingSmall: 'Utbud',
  sectionHeading: 'Föreläsningar',
  infoHeading: 'Föreläsningar som inspirerar',
  introTxt: [
    'Sherpifys föreläsningar grundar sig i passion för äventyret och kastar sig mellan hisnande berättelser om toppbestigningar, projektledning och livet som digital nomad.',
    'Vi pratar om ledarskap i extrema miljöer och om att skapa tillit i teamet. Om att alltid följa sitt hjärta och att våga utmana sig för att nå målet.',
  ],
  btnTxt: 'Kontakta oss',
};
export const lecturesSecTxtEng = {
  sectionHeadingSmall: 'Services',
  sectionHeading: 'Lectures',
  infoHeading: 'Lectures that inspire',
  introTxt: [
    "Sherpify's lectures are based on the passion for adventure and toss between breathtaking stories of summits, project management and life as a digital nomad.",
    'We talk about leadership in extreme environments and about creating trust in the team. About the importance of always following your heart and daring to challenge yourself to reach your goal.',
  ],
  btnTxt: 'Contact us',
};
/* LECTURES - data */
export const lectures = [
  {
    imgUrl: 'lectures/lecture_leadership_in_extreme_environments.jpg',
    imgAlt: 'En expedition längs en snötäckt smal bergskam',
    title: 'Ledarskap i extrema miljöer',
    intro: 'Behåll lugnet i pressade situationer',
    text: [
      'Att leda grupper i extrema miljöer är en enorm utmaning där det största ansvaret ligger på ledaren - du behöver skapa en tillit som gör att din grupp känner trygghet i de beslut du tar. Du behöver dessutom kunna agera snabbt i pressade situationer och motivera ditt team framåt för att komma i mål.',
      'Efter 27 år som bergsguide och med åtta toppstigningar på Mount Everest, vet Pemba hur det är att behålla lugn och leda en grupp framåt i extrema miljöer. Pemba använder sin långa erfarenhet i Himalaya när han i sina förtrollande föreläsningar berättar om ledarskap i situationer utöver det vanliga.',
    ],
    price: 'Pris från: 00 000 SEK',
    btnTxt: 'Kontakta oss',
  },
  {
    imgUrl: 'lectures/lecture_pemba2.jpg',
    imgAlt: 'Pemba från sidan',
    title: 'Pemba, från sherpabonde till Hollywoodstjärna',
    intro: 'Förtrollande föreläsningar om livet som bergsguide.',
    text: [
      'Följ med på Pembas fantastiska inspirationsföreläsning där han berättar om livet i Nepal och uppväxten i en liten by på 2600 meters höjd. Han pratar om livet som bonde, bristen på utbildning och de utmaningar han mött på vägen från byn i Nepal till Kolmården i Sverige.',
      'När Pemba bara var fem år fick han ansvaret för att familjens kor skulle komma ut på bete i skogen. När han var 14 år fick Pemba möjlighet att arbeta som porter på expeditioner i Himalaya, där han sen avancerade till kökspojke och kock. När han fick möjlighet att följa med en amerikansk expedition till toppen av Mount Everest tvekade han inte en sekund. Det var starten på något som skulle bli en briljant karriär som bergsguide i Himalaya, där Pemba lett åtta toppbestigningar på Mount Everest.',
      'Idag har Pemba arbetat länge som bergsguide och han har genom det fått möjlighet att resa till bland annat Sverige och Brasilien för att bestiga berg. 2014 blev han erbjuden en roll i Hollywoodfilmen Everest, något som tog Pemba till London, Venedig och till slut röda mattan i Cannes!',
      'Pembas föreläsning är ett hisnande äventyr om ett liv där mod och en vilja att utmanas har tagit honom från Himalaya till Kolmården och tillbaka igen!',
    ],
    price: 'Pris från: 00 000 SEK',
    btnTxt: 'Kontakta oss',
  },

  {
    imgUrl: 'lectures/lecture_digital_nomad2.png',
    imgAlt: 'Helene sitter och blickar ut över en dalgång',
    title: 'Digital nomad',
    intro: 'Om att ha modet att våga gå sin egen väg.',
    text: [
      'Mitt i den brinnande pandemin tog Helene tag i den där känslan som skavde. När alla andra satt stilla i båten, tog Helene beslutet om att hoppa av sin fasta anställning för att starta egen konsultverksamhet.',
      'Livet som anställd kändes alltid instängt - även om projekten Helene drivit varit utvecklande och intressanta så har längtan efter friheten alltid funnits där. Att ge sig ut på äventyr runt om i världen är ett livselixir för Helene och hon visste att det måste gå att kombinera med att bygga upp en framgångsrik karriär.',
      'Idag driver Helene Sherpify från sin dator, från den plats hon befinner sig för tillfället och Helenes dröm är att Sherpify ska utökas med fler skarpa konsulter och äventyrare. Lyssna på Helenes spännande föreläsningar om livet som digital nomad och om modet att våga gå sin egen väg.',
    ],
    price: 'Pris från: 00 000 SEK',
    btnTxt: 'Kontakta oss',
  },

  {
    imgUrl: 'lectures/lecture_customized.jpg',
    imgAlt: 'En smal brygga leder ut i vattnet',
    title: 'Skräddarsydd',
    intro: 'Skräddarsy din föreläsning efter egna önskemål.',
    text: [
      'Vill du ha en föreläsning som passar dig och ditt team sätter vi ihop det efter dina önskemål. Vi pratar om äventyr, ledarskap och snabba beslut på svindlande höjder. Kontakta oss så sätter vi ihop den perfekta föreläsningen för er!',
    ],
    price: 'Pris från: 00 000 SEK',
    btnTxt: 'Kontakta oss',
  },
];
export const lecturesEng = [
  {
    imgUrl: 'lectures/lecture_leadership_in_extreme_environments.jpg',
    imgAlt: 'An expedition along a snow-covered narrow mountain ridge',
    title: 'Leadership in extreme environments',
    intro: 'Keep calm in extreme situations.',
    text: [
      'Leading groups in extreme environments is an immense challenge where the greatest responsibility lies with the leader. You need to build trust that makes your team feel secure in the decisions you make. Additionally, you must be able to act swiftly in extreme situations and motivate your team to move forward to reach the goal.',
      'With 27 years experience as a mountain guide and eight successful summits of Mount Everest, Pemba knows how to remain calm and lead a group forward in extreme environments. Pemba draws upon his extensive experience in the Himalayas to captivate his audience in his enchanting lectures about leadership in extraordinary situations.',
    ],
    price: 'Price from: 00 000 SEK',
    btnTxt: 'Contact us',
  },
  {
    imgUrl: 'lectures/lecture_pemba2.jpg',
    imgAlt: 'Pemba from the side',
    title: 'Pemba, a farmer boy becoming Hollywood star',
    intro: 'Enchanting lectures about life in the mountains.',
    text: [
      'Join Pemba on his incredible inspirational lecture where he shares the story of his life in the Himalayas and his upbringing in a small village at 2600 meters above sea level. He talks about the life as a farmer, the lack of education, and the challenges he faced on his journey from the village in Nepal, up on Mount Everest, to Hollywood and finally to Kolmården in Sweden.',
      "When Pemba was just five years old, he was given the responsibility of herding the family's cows into the forest for grazing. At the age of 14, Pemba had the opportunity to work as a porter on expeditions in the Himalayas, where he later advanced to become a kitchen assistant and cook. When he had the chance to join an American expedition to the summit of Mount Everest, he didn't hesitate for a moment. That marked the beginning of what would become a brilliant career as a mountain guide in the Himalayas, where Pemba has led eight successful summits of Mount Everest.",
      'Today, Pemba has worked as a mountain guide for a long time, and through that, he has had the opportunity to travel to places like Sweden and Brazil to climb mountains. In 2014, he was offered a role in the Hollywood movie Everest, which took Pemba to London, Venice, and eventually the red carpet in Cannes!',
      "Pemba's lecture is a breathtaking adventure about a life where courage and a willingness to be challenged have taken him from the Himalayas to Kolmården and back again!",
    ],
    price: 'Price from: 00 000 SEK',
    btnTxt: 'Contact us',
  },

  {
    imgUrl: 'lectures/lecture_digital_nomad2.png',
    imgAlt: 'Helene sits and gazes out over a valley',
    title: 'Digital nomad',
    intro: 'About having the courage to go your own way.',
    text: [
      'In the middle of the raging pandemic, Helene decided to act on that nagging feeling. While everyone else remained still in the boat, Helene made the decision to resign from her job and start her own consulting business.',
      'Life as an employee always felt confined – even though the projects Helene managed were challenging and interesting, the longing for freedom was always present. Embarking on new adventures around the world is a life elixir for Helene, and she knew it must be possible to combine it with building a successful career.',
      "Today, Helene runs Sherpify from her computer, working remote, and Helene's dream is to expand Sherpify with more skilled consultants and adventurers with similar dreams. Listen to Helene's exciting lectures about life as a digital nomad and the courage to challenge status quo and create your own path.",
    ],
    price: 'Price from: 00 000 SEK',
    btnTxt: 'Contact us',
  },

  {
    imgUrl: 'lectures/lecture_customized.jpg',
    imgAlt: 'A narrow bridge leads out into the water',
    title: 'Customized',
    intro: 'Customize your lecture according to your own preferences.',
    text: [
      "If you want a lecture that suits you and your team, we'll tailor it to your preferences. We talk about adventure, leadership, risk management and quick decision-making in extreme situations. Contact us, and we'll create the perfect lecture for you!",
    ],
    price: 'Price from: 00 000 SEK',
    btnTxt: 'Contact us',
  },
];
/* LECTURERS - heading */
export const lecturersSecTxt = {
  sectionHeadingSmall: 'Våra föreläsare',
  sectionHeading: 'Vi som föreläser',
};
export const lecturersSecTxtEng = {
  sectionHeadingSmall: 'Our lecturers',
  sectionHeading: 'We who lecture',
};

/*********** ACTIVITIES PAGE ***********/
/* INTRO - heading */
export const activitiesIntroTxt = {
  imgUrl: 'activities_intro.png',
  imgAlt: 'Pemba på ett snötäckt berg med utsträckta armar',
  sectionHeadingSmall: '',
  sectionHeading: 'Utmana ditt team med våra aktiviteter',
  bodyTxt: [
    'På Sherpify har vi satt ihop ett flertal olika gruppaktiviteter, där någon kommer passa ditt team. Vi har allt från endagsäventyr i Kolmårdens trollskogar, till tuffare resor i Kebnekaise och Nepal. När ni deltar i våra teamaktiviteter befinner ni er i storslagen natur och ni delar upplevelser som bygger gruppen och som ni har glädje av länge efteråt.',
    'Vi kommer utmana er att våga och växa tillsammans under ledning av en av världens mest erfarna bergsguider.',
  ],
  poppingTxt: 'Hur mycket vågar ditt team?',
};
export const activitiesIntroTxtEng = {
  imgUrl: 'activities_intro.png',
  imgAlt: 'Pemba on a snow-covered mountain with arms outstretched',
  sectionHeadingSmall: '',
  sectionHeading: 'Challenge your team with our activities',
  bodyTxt: [
    'At Sherpify, we have put together a variety of group activities, where one will hopefully suit your team. We offer everything from one-day adventures in the enchanted forests of Kolmården to more challenging trips to Kebnekaise and Nepal. When you participate in our team activities, you find yourselves in magnificent nature, sharing experiences that build the group and provide lasting joy.',
    "We will challenge you to dare a bit more and grow together under the guidance of one of the world's most experienced mountain guides.",
  ],
  poppingTxt: 'How much is your team willing to dare?',
};
/* ACTIVITIES - heading */
export const activitiesSecTxt = {
  sectionHeadingSmall: 'Utbud',
  sectionHeading: 'Vad vi erbjuder',
  introTxt:
    'Sherpify ser till att din grupp utmanas och utvecklas i miljöer som ligger utanför er bekvämlighetszon. Våra aktiviteter är utöver det vanliga!',
};
export const activitiesSecTxtEng = {
  sectionHeadingSmall: 'Services',
  sectionHeading: 'What we offer',
  introTxt:
    'Sherpify ensures that your group is challenged and develops in environments that might be a little bit beyond your comfort zone. Our activities are extraordinary!',
};
/* ACTIVITIES - data */
export const activities = [
  {
    imgUrl: 'activities/kolmarden1.jpg',
    imgAlt: 'Helene klättrar uppför ett berg, skog i bakgrunden',
    imgList: [
      'activities/kolmarden1.jpg',
      'activities/kolmarden2.jpg',
      'activities/sleep_outside1.jpg',
      'activities/kolmarden3.jpg',
    ],
    title: 'Bygg ditt team i Kolmårdens vildmark',
    icon: 'climb',
    intro: 'En sammansvetsande aktivitet i storslagen natur',
    text: [
      'I skogarna runt Kolmården får gruppen uppgifter som i sig kan verka enkla men som utmanar både ledarskap och individer. Tillsammans gör vi upp eld, lagar mat i det vilda och vandrar i trollskogar. Deltagarna har olika arbetsuppgifter där alla är lika viktiga och behövs för att nå fram till önskat resultat.',
    ],
    price: 'Pris från: 00 000 SEK',
    btnTxt: 'Kontakta oss',
  },
  {
    imgUrl: 'activities/sleep_outside1.jpg',
    imgAlt: 'Ett tält vid en sjö i solnedgången',
    imgList: [
      'activities/sleep_outside1.jpg',
      'activities/sleep_outside2.jpg',
      'activities/sleep_outside3.jpg',
    ],
    title: 'En natt under bar himmel',
    icon: 'sleepingBag',
    intro:
      'Bygg ditt team genom aktiviteter i en miljö som utmanar och utvecklar.',
    text: [
      'Efter en dag i skogarna runt Kolmården, där gruppen utmanats genom vandring och bergsklättring, samlas vi runt lägerelden. Vi lagar mat över öppen eld och tillbringar sedan natten i tält. Vi deltar i utmanande uppgifter, där gruppen får lära sig att vi tillsammans kan lösa problem även i svåra situationer.',
    ],
    price: 'Pris från: 00 000 SEK',
    btnTxt: 'Kontakta oss',
  },
  {
    imgUrl: 'activities/kebnekajse1.jpg',
    imgAlt:
      'En person står på kanten av ett berg och en sjö skymtas långt nere',
    imgList: ['activities/kebnekajse1.jpg'],
    title: 'Led din grupp till toppen av Kebnekaise',
    icon: 'mountainRoad',
    intro:
      'Teamaktiviteter i en storslagen fjällupplevelse för en sammansvetsad grupp.',
    text: [
      'Följ med Sherpify på en topptur till Kebnekaise – här krävs mod och en enorm vilja av hela teamet. Belöningen är en fantastisk och utmanande fjällupplevelse med storslagen natur där gruppen svetsas samman för att gemensamt ta sig till toppen.',
    ],
    price: 'Pris från: 00 000 SEK',
    btnTxt: 'Kontakta oss',
  },
  {
    imgUrl: 'activities/authentic_nepal1.jpg',
    imgAlt: 'Ett nepalesiskt par i deras hem',
    imgList: [
      'activities/authentic_nepal1.jpg',
      'activities/authentic_nepal4.jpg',
      'activities/authentic_nepal2.jpg',
      'activities/authentic_nepal3.jpg',
      'activities/authentic_nepal5.jpg',
    ],
    title: 'Ta med ditt team till det autentiska Nepal',
    icon: 'hike',
    intro:
      'En upplevelse tillsammans på 4000 meters höjd som slår det mesta - det här är något för riktiga äventyrare.',
    text: [
      'Söker du en ordentlig utmaning och en upplevelse du aldrig glömmer, kan du och din grupp följa med Sherpify på en längre teamaktivitet där äventyret svetsar er samman och ni tillsammans når nya mål.',
      'Vi tar er med på en fantastisk och hisnande resa när vi besöker det autentiska Nepal. Ni får bo med sherpafolket i Pembas hemby Patle på 2600 höjd. Invånarna där lever än i dag som bönder, de bor i enkla hus och maten lagas över eld inomhus.',
      'Från byn gör vi dagsturer och bestiger Peaky Peak, en topp på 4000 meter över havet. Den här resan ger gruppen en chans att reflektera och komma nära långt bort från västvärldens krav och störande moment, och ger er en upplevelse för livet.',
    ],
    price: 'Pris från: 00 000 SEK',
    btnTxt: 'Kontakta oss',
  },

  {
    imgUrl: 'activities/spiritual_trip1.png',
    imgAlt: 'Helene och Pemba tänder ljus',
    imgList: [
      'activities/spiritual_trip1.png',
      'activities/spiritual_trip2.jpg',
      'activities/spiritual_trip3.jpg',
      'activities/spiritual_trip5.png',
      'activities/spiritual_trip4.jpg',
    ],
    title: 'Spirituell resa till Nepal',
    icon: 'temple',
    intro: 'Följ med Sherpify på ett inre äventyr i reflektionens tecken.',
    text: [
      'Vi tar dig med till det autentiska Nepal, till bergen och stillheten. Där lägger vi tiden på att lyssna inåt och få nya perspektiv.',
      'Vi besöker munkkloster och får ta del av buddhistiska ritualer och tusentals år av vishet. Tillsammans med munkarna sitter vi i tysthet och mediterar i samklang med den storslagna naturen.',
      'Det här är en resa där du och ditt team kommer ner i varv och skapar utrymme för tankar och vägar framåt. Ni lyssnar inåt och reflekterar över er själva och era individuella sätt att vara, och öppnar upp möjligheter för förändring.',
    ],
    price: 'Pris från: 00 000 SEK',
    btnTxt: 'Kontakta oss',
  },
  {
    imgUrl: 'activities/customized1.png',
    imgAlt: 'Helene vandrar bland bergen',
    imgList: [
      'activities/customized1.png',
      'activities/customized2.jpg',
      'activities/customized3.jpg',
      'activities/customized4.jpg',
      'activities/customized5.jpg',
    ],
    title: 'Vi skräddarsyr efter dina önskemål',
    icon: 'needle',
    intro: 'Tillsammans hittar vi aktiviteten som passar ditt team.',
    text: [
      'Vill du skapa en aktivitet som utmanar och utvecklar ditt team hjälper vi dig att skräddarsy en upplevelse som passar just er. Vi tar hjälp av vår långa erfarenhet, vår passion för äventyret och sätter tillsammans med dig ihop ett paket som är perfekt för ditt företag eller grupp.',
    ],
    price: 'Pris från: 00 000 SEK',
    btnTxt: 'Kontakta oss',
  },
];
export const activitiesEng = [
  {
    imgUrl: 'activities/kolmarden1.jpg',
    imgAlt: 'Helene is climbing up a mountain, with a forest in the background',
    imgList: [
      'activities/kolmarden1.jpg',
      'activities/kolmarden2.jpg',
      'activities/sleep_outside1.jpg',
      'activities/kolmarden3.jpg',
    ],
    title: 'Build your team in the wilderness of Kolmården',
    icon: 'climb',
    intro: 'A team-building activity in magnificent nature.',
    text: [
      'In the forests around Kolmården, the group is given tasks that may seem simple but challenge both leadership and individuals. Together, we build a fire, cook food in the wild, and hike through enchanted forests. Participants have different roles where everyone is equally important and necessary to achieve the desired outcome.',
    ],
    price: 'Price from: 00 000 SEK',
    btnTxt: 'Contact us',
  },
  {
    imgUrl: 'activities/sleep_outside1.jpg',
    imgAlt: 'A tent by a lake at sunset',
    imgList: [
      'activities/sleep_outside1.jpg',
      'activities/sleep_outside2.jpg',
      'activities/sleep_outside3.jpg',
    ],
    title: 'A night under the open sky',
    icon: 'sleepingBag',
    intro:
      'Build your team through activities in an environment that challenges and develops.',
    text: [
      'After a day in the forests around Kolmården, where the group has been challenged through hiking and rock climbing, we gather around the campfire. We cook food over an open fire and then spend the night in tents or windshelters. We participate in challenging tasks where the group learns that together we can solve problems even in difficult situations.',
    ],
    price: 'Price from: 00 000 SEK',
    btnTxt: 'Contact us',
  },
  {
    imgUrl: 'activities/kebnekajse1.jpg',
    imgAlt:
      'A person stands on the edge of a mountain, with a distant view of a lake below',
    imgList: ['activities/kebnekajse1.jpg'],
    title: 'Bring your group to the top of Kebnekaise',
    icon: 'mountainRoad',
    intro: 'Team activities combining magnificent mountain experiences.',
    text: [
      'Join Sherpify on a summit trip to Kebnekaise - here, courage and determination are required from the entire team. The reward is a fantastic and challenging mountain experience in magnificent nature where the group comes together to collectively reach the summit.',
    ],
    price: 'Price from: 00 000 SEK',
    btnTxt: 'Contact us',
  },
  {
    imgUrl: 'activities/authentic_nepal1.jpg',
    imgAlt: 'A Nepali couple in their home',
    imgList: [
      'activities/authentic_nepal1.jpg',
      'activities/authentic_nepal4.jpg',
      'activities/authentic_nepal2.jpg',
      'activities/authentic_nepal3.jpg',
      'activities/authentic_nepal5.jpg',
    ],
    title: 'Bring your team to authentic Nepal',
    icon: 'hike',
    intro:
      'An experience at 4000 meters above sea level that you have never seen before - this is something for real adventurers.',
    text: [
      "If you're looking for an unforgettable experience, you and your group can join Sherpify on an extended team activity where the adventure bonds you together, and you togehther you achieve new goals.",
      "We take you on a unique journey where you’ll visit the authentic Nepal. Here you will live with the Sherpa people in Pemba's hometown of Patle at 2600 meters. The sherpas still live as farmers, in simple traditional houses, where food is cooked over indoor fires.",
      'From the village, we go on day trips and ascend Peaky Peak, a summit at 4000 meters above sea level. This journey provides the group with an opportunity to reflect, far from the demands and distractions of the modern Western world, and gives you an experience of a lifetime.',
    ],
    price: 'Price from: 00 000 SEK',
    btnTxt: 'Contact us',
  },

  {
    imgUrl: 'activities/spiritual_trip1.png',
    imgAlt: 'Helene and Pemba lighting candles',
    imgList: [
      'activities/spiritual_trip1.png',
      'activities/spiritual_trip2.jpg',
      'activities/spiritual_trip3.jpg',
      'activities/spiritual_trip5.png',
      'activities/spiritual_trip4.jpg',
    ],
    title: 'Spiritual journey to Nepal',
    icon: 'temple',
    intro: 'Join Sherpify on an inner adventure of reflection.',
    text: [
      'We wil take you to the authentic parts of Nepal, to the mountains and the stillness. There, we spend time listening inwards and gaining new perspectives.',
      'We visit monasteries and take part in Buddhist rituals and thousands of years of wisdom. Together with the monks, we sit in silence and meditate in harmony with the magnificent nature.',
      'This is a journey where you and your team slow down and create space for new thoughts to enter your minds. You listen inward and reflect on yourselves and your individual ways of being, opening up possibilities for change.',
    ],
    price: 'Price from: 00 000 SEK',
    btnTxt: 'Contact us',
  },
  {
    imgUrl: 'activities/customized1.png',
    imgAlt: 'Helene is hiking among the mountains.',
    imgList: [
      'activities/customized1.png',
      'activities/customized2.jpg',
      'activities/customized3.jpg',
      'activities/customized4.jpg',
      'activities/customized5.jpg',
    ],
    title: 'We tailor your activity',
    icon: 'needle',
    intro: 'Together, we find the activity that suits your team.',
    text: [
      'If you wish to create an activity that challenges and develops your team, we can help you tailor an experience that suits you perfectly. We use our experience, our passion for adventure and together with you, we’ll put together a package that is suits your company or group.',
    ],
    price: 'Price from: 00 000 SEK',
    btnTxt: 'Contact us',
  },
];
/* CONTACT BANNER */
export const contactBannerSecTxt = {
  sectionHeadingSmall: 'Kontakt',
  sectionHeading: 'Hittade du inte vad du sökte?',
  bodyTxt:
    'Kontakta oss om du vill ha mer information om Sherpify och vårt arbete.',
  btnTxt: 'Kontakta oss',
};
export const contactBannerSecTxtEng = {
  sectionHeadingSmall: 'Contact',
  sectionHeading: "Didn't find what you were looking for?",
  bodyTxt:
    'Don’t hesitate to contact us if you want more information about Sherpify and our work.',
  btnTxt: 'Contact us',
};
/* REVIEWS - heading */
export const activitiesReviewSecTxt = {
  sectionHeadingSmall: 'Rekommendationer',
  sectionHeading: 'Röster från kunder',
  linkTxt: 'Läs hela texten',
  btnTxt: 'Visa mer',
};
export const activitiesReviewSecTxtEng = {
  sectionHeadingSmall: 'Recommendations',
  sectionHeading: 'Voices from customers',
  linkTxt: 'Read more',
  btnTxt: 'Show more',
};
/* REVIEWS - data */
export const activitiesReviews = [
  // {
  //   name: 'Namn1',
  //   date: 'DD månad ÅÅÅÅ',
  //   review: [
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //   ],
  // },
];
export const activitiesReviewsEng = [
  // {
  //   name: '!Namn1',
  //   date: 'DD månad ÅÅÅÅ',
  //   review: [
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //   ],
  // },
];

/*********** SHARED COMPONENTS ***********/
/* HEADER */
export const headerSecTxt = {
  home: 'hem',
  services: 'utbud',
  consulting: 'konsulting',
  lectures: 'föreläsningar',
  activities: 'aktiviteter',
  about: 'om oss',
  contact: 'kontakt',
};
export const headerSecTxtEng = {
  home: 'home',
  services: 'services',
  consulting: 'consulting',
  lectures: 'lectures',
  activities: 'activities',
  about: 'about',
  contact: 'contact',
};
/* LANDING */
export const landingSecTxt = {
  linkConsult: 'Konsulting',
  linkLectures: 'Föreläsningar',
  linkActivities: 'Aktiviteter',
  imgList: ['landing3.jpg', 'landing1.jpg', 'landing2.jpg', 'landing4.jpg'],
  textList: [
    {
      largeHeading: 'Sherpify tar äventyret till svenska företag',
      smallHeading: 'Ledarskapsutveckling från bergstopparna i Himalaya',
    },
    {
      largeHeading: 'Konsult som utmanar',
      smallHeading: '',
    },
    {
      largeHeading: 'Sherpify föreläser om äventyr och ledarskap',
      smallHeading: '',
    },
    {
      largeHeading: 'Utmana ditt team med våra aktiviteter',
      smallHeading: '',
    },
  ],
};
export const landingSecTxtEng = {
  linkConsult: 'Consulting',
  linkLectures: 'Lectures',
  linkActivities: 'Activities',
  imgList: ['landing3.jpg', 'landing1.jpg', 'landing2.jpg', 'landing4.jpg'],
  textList: [
    {
      largeHeading: 'Sherpify brings adventure to Swedish companies',
      smallHeading: 'Leadership development from the Himalayas',
    },
    {
      largeHeading: 'Consultant who challenges',
      smallHeading: '',
    },
    {
      largeHeading: 'Sherpify lectures about adventure and leadership',
      smallHeading: '',
    },
    {
      largeHeading: 'Challenge your team with our activities',
      smallHeading: '',
    },
  ],
};
/* CONTACT */
export const contactSecTxt = {
  sectionHeadingSmall: 'Kontakt',
  sectionHeading: 'Hur kan vi hjälpa dig?',
  infoTxt:
    'Hör gärna av dig till Sherpify om du har några frågor så återkommer vi så snart vi kan!',
  placeholderName: 'Namn',
  placeholderCompany: 'Företag',
  placeholderPhoneNumber: 'Telefonnummer',
  placeholderEmail: 'E-post',
  placeholderMessage: 'Skriv ditt meddelande här',
  btnTxt: 'Skicka',
  submitTxt: ['Meddelande skickat', 'Tack för att du kontaktar oss!'],
  submitBtn: 'Skicka ett nytt meddelande',
  gdprTxt: 'Jag samtycker till att mina uppgifter behandlas enligt gällande ',
  gdprLink: 'integritetspolicy',
};
export const contactSecTxtEng = {
  sectionHeadingSmall: 'Contact',
  sectionHeading: 'How can we help you?',
  infoTxt:
    'Feel free to contact us at Sherpify if you have any questions, and we will get back to you as soon as we can!',
  placeholderName: 'Name',
  placeholderCompany: 'Company',
  placeholderPhoneNumber: 'Phone number',
  placeholderEmail: 'E-mail',
  placeholderMessage: 'Write your message here',
  btnTxt: 'Send',
  submitTxt: ['Message sent', 'Thank you for contacting us!'],
  submitBtn: 'Send a new message',
  gdprTxt:
    'I consent to my data being processed in accordance with the current ',
  gdprLink: 'privacy policy',
};
export const gdprSecTxt = {
  heading: 'Integritetspolicy för Sherpify AB',
  text: [
    'Senast uppdaterad: 2023-09-17',
    'Denna policy beskriver hur vi samlar in, använder, delar och skyddar din personliga information. Vi värdesätter ditt förtroende och är engagerade i att skydda din integritet. Läs igenom policyn noggrant för att förstå hur vi hanterar din information.',
    '1. Insamling av information',
    'Vi samlar in olika typer av information när du besöker vår webbplats, använder våra tjänster eller kommunicerar med oss. Det kan inkludera personlig information som namn, e-postadress, telefonnummer och företagsinformation. Vi samlar också in icke-personlig information såsom webbläsartyp, IP-adress, webbsidor du besöker och demografisk information.',
    '2. Användning av information',
    'Vi använder den insamlade informationen för att förbättra våra tjänster, anpassa din användarupplevelse och kommunicera med dig. Det kan inkludera att skicka e-postmeddelanden, marknadsföring eller annan relevant information om våra produkter och tjänster. Vi kan också använda informationen för att analysera trender och förbättra webbplatsens funktionalitet.',
    '3. Delning av information',
    'Vi delar inte din personliga information med tredje parter utan ditt samtycke, förutom i följande situationer:',
    '- Med våra auktoriserade tjänsteleverantörer som hjälper oss att leverera tjänster till dig.',
    '- När det krävs enligt lag eller myndighetsbeslut.',
    '- För att skydda våra rättigheter, säkerhet och egendom.',
    '4. Dataskydd och säkerhet',
    'Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att skydda din information mot obehörig åtkomst, förlust, missbruk eller förändring. Vi strävar efter att upprätthålla högsta säkerhetsstandard.',
    '5. Dina rättigheter',
    'Du har rätt att begära åtkomst till, korrigering av eller radering av dina personuppgifter. Du kan också invända mot hur vi behandlar din information eller begränsa dess användning. Kontakta oss om du vill utöva någon av dessa rättigheter.',
    '6. Cookies och spårningsteknik',
    'Vi använder cookies och liknande tekniker för att förbättra din upplevelse på vår webbplats. Du kan hantera dina cookie-inställningar i webbläsaren. Läs vår Cookiepolicy för mer information.',
    '7. Ändringar i integritetspolicyn',
    'Vi kan uppdatera denna integritetspolicy med jämna mellanrum. Vi meddelar dig om det sker väsentliga förändringar. Policyn kommer alltid att finnas tillgänglig på vår webbplats.',
    '8. Kontakta oss',
    'Om du har frågor, kommentarer eller bekymmer angående denna integritetspolicy eller vår hantering av din information, kontakta oss på hello@sherpify.se. ',
    'Genom att använda vår webbplats och tjänster samtycker du till denna integritetspolicy.',
    'Denna integritetspolicy gäller från och med [datum].',
  ],
};
export const gdprSecTxtEng = {
  heading: 'Privacy Policy for Sherpify AB',
  text: [
    'Last Updated: 2023-09-17',
    'This policy describes how we collect, use, share, and protect your personal information. We value your trust and are committed to protecting your privacy. Please read the policy carefully to understand how we handle your information.',
    '1. Collection of Information',
    'We collect various types of information when you visit our website, use our services, or communicate with us. This may include personal information such as your name, email address, phone number, and company information. We also collect non-personal information such as browser type, IP address, web pages you visit, and demographic information.',
    '2. Use of Information',
    'We use the collected information to improve our services, personalize your user experience, and communicate with you. This may include sending email messages, marketing, or other relevant information about our products and services. We may also use the information to analyze trends and enhance website functionality.',
    '3. Sharing of Information',
    'We do not share your personal information with third parties without your consent, except in the following situations:',
    '- With our authorized service providers who assist us in delivering services to you.',
    '- When required by law or government decision.',
    '- To protect our rights, safety, and property.',
    '4. Data Protection and Security',
    'We take appropriate technical and organizational measures to protect your information from unauthorized access, loss, misuse, or alteration. We strive to maintain the highest security standards.',
    '5. Your Rights',
    'You have the right to request access to, correction of, or deletion of your personal data. You can also object to how we process your information or restrict its use. Please contact us if you wish to exercise any of these rights.',
    '6. Cookies and Tracking Technology',
    'We use cookies and similar technologies to enhance your experience on our website. You can manage your cookie settings in your browser. Please read our Cookie Policy for more information.',
    '7. Changes to the Privacy Policy',
    'We may update this privacy policy periodically. We will notify you of significant changes. The policy will always be available on our website.',
    '8. Contact Us',
    'If you have questions, comments, or concerns about this privacy policy or our handling of your information, please contact us at hello@sherpify.se.',
    'By using our website and services, you consent to this privacy policy.',
    'This privacy policy is effective from 2023-09-17.',
  ],
};
/* SUPPORT BANNER */
export const supportSecTxt = {
  mainTxtMob: 'Sherpify stödjer',
  mainTxtDesk: 'Sherpify stödjer Ascent Aid Nepal',
  logoUrl: 'logo_aan_standing.png',
  logoAlt: 'Ascent Aid Nepals logotyp',
  bodyTxt: 'Besök ',
  linkTxt: 'ascentaidnepal.org',
  bodyTxt2: ' för att läsa mer om deras arbete',
};
export const supportSecTxtEng = {
  mainTxtMob: 'Sherpify support',
  mainTxtDesk: 'Sherpify support Ascent Aid Nepal',
  logoUrl: 'logo_aan_standing.png',
  logoAlt: 'Ascent Aid Nepal logo',
  bodyTxt: 'Visit ',
  linkTxt: 'ascentaidnepal.org',
  bodyTxt2: ' to read more about their work',
};
/* FOOTER - data */
export const footerSecTxt = {
  name: 'Sherpify AB',
  address: 'Kvarsebovägen 130',
  zipCode: '618 93',
  city: 'Kolmården',
  orgNr: 'Org.nr 559268-3691',
  socialMedia: [
    {
      name: 'E-post',
      details: 'hello@sherpify.se',
      icon: 'eMail',
      link: 'mailto:hello@sherpify.se',
    },
    {
      name: 'Linkedin',
      details: '@SherpifyAB',
      icon: 'linkedin',
      link: 'https://linkedin.com/company/sherpifyab',
    },
    // {
    //     name: 'Instagram',
    //     details: '@SherpifyAB',
    //     icon: 'instagram',
    //     link: 'http://www.google.com'
    // }
  ],
};
export const footerSecTxtEng = {
  name: 'Sherpify AB',
  address: 'Kvarsebovägen 130',
  zipCode: '618 93',
  city: 'Kolmården',
  orgNr: 'CRN 559268-3691',
  socialMedia: [
    {
      id: 1,
      name: 'E-mail',
      details: 'hello@sherpify.com',
      icon: 'eMail',
      link: 'mailto:hello@sherpify.se',
    },
    {
      id: 2,
      name: 'Linkedin',
      details: '@SherpifyAB',
      icon: 'linkedin',
      link: 'https://linkedin.com/company/sherpifyab',
    },
    // {
    //   id: 3,
    //   name: 'Instagram',
    //   details: '@SherpifyAB',
    //   icon: 'instagram',
    //   link: 'http://www.google.com',
    // },
  ],
};

/*  HOME - ABOUT / CONSULTING - CONSULTANTS / LECTURES - LECTURERS - data */
export const people = [
  {
    id: 1,
    name: 'Helene Sherpa',
    title: 'Konsult inom projektledning',
    title2: 'VD Sherpify',
    imgUrlSquare: 'people/helene_square2.jpg',
    imgSquareAlt: 'Helene',
    mail: 'mailto:helene@sherpify.se',
    linkedin: 'https://www.linkedin.com/in/helenesherpa/',

    isConsult: true,
    consultantImg: 'people/consultant_helene2.jpg',
    consultantAlt: 'Helene sitter framför vid sitt skrivbord i Nepal',
    consultingSecTxt: {
      sectionHeadingSmall: 'Våra konsulter',
      sectionHeading: 'Helene simmar mot strömmen',
    },
    consultLongerText: [
      'Men en rastlöst stampande fot och blicken riktad framåt har Helene byggt upp sin konsultkarriär. Att driva projekt från start till målgång är något som får Helenes hjärta att slå snabbare. Kombinationen av att leda och stötta teamet men också att hitta smartare vägar för en effektiv lösning gör att Helene brinner för de projekt hon driver.',
      'Helene fann sin passion för projektledning och verksamhetsutveckling när hon skapade sin drömroll för Saab för 15 år sedan och har sedan dess tagit fram lösningar för ett stort antal företag. Drivet ligger i att vara med från början genom analys och strategiplanering för att sedan föra projektet i mål tillsammans med team och kunden.',
    ],
    projects: [
      {
        projectName: 'Senior Projektledare',
        projectCompany: 'Dell Technologies',
        projectText:
          'Dells transformationsprojekt där Helene som senior program manager var ansvarig för flera projekt parallellt. Teamet var utspridd över Norden vilket skapade unika och utmanande förutsättningar för att nå målet enligt plan.',
      },
      {
        projectName: 'IT-projektledare',
        projectCompany: 'Stångåsstaden',
        projectText:
          'Stångåstaden anställde Helene som konsult för att driva utvecklingen av en ny app för hyresgästerna. Projektet startade med kundundersökningar och analys av möjliga lösningar och för att sedan utvecklas till en färdig app till kunden.',
      },
      {
        projectName: 'Senior Projektledare',
        projectCompany: 'DevPort',
        projectText:
          'Helene var senior projektledare med ansvar för tre team där syftet var att bygga hårdvara, mjukvara och androidappar som ska styra ett sensorsystem.',
      },
      {
        projectName: 'Projektledare',
        projectCompany: 'Saab',
        projectText:
          'Helene agerade huvudprojektledare för att ta fram ett nytt administrativt system för Saabs Gripenaffärer. Helene tog projektet från kravanalys till implementering och systemet har sedan dess införts på samtliga affärsenheter inom Saab.',
      },
    ],
    isConsultingReviews: true,
    consultingReviewSecTxt: {
      sectionHeadingSmall: 'Rekommendationer',
      sectionHeading: 'Vi rekommenderar Helene',
      linkTxt: 'Läs hela texten',
      btnTxt: 'Visa fler',
    },
    consultingReviews: [
      {
        name: 'Karina Visholm Andersen',
        role: 'Senior Service Delivery Manager, Dell Technologies',
        date: '17 oktober 2022',
        review: [
          'I have had the pleasure of working with Helene now for two large accounts within Dell. During this year!',
          'Helene’s skills are many but if I was to list just a few they would be: Commitment –Helene goes to great lengths to reach  the goal, she is determined and has a fantastic ability to stay focused on a task for a long period of time.',
          'When working with Helene you never have a dull moment. She is extremely eager, to get things completed. Upon completion follows a great bunch of high quality and an eye for detail. She has a great understanding of the business and is really creative and constantly comes up with ideas for improvements.',
          'Helene is contracted by Dell but acts as if we are one company and is highly appreciated by all members of the team and especially me. Helene is organized, structured, and very self-driven.',
          'What you can expect from Helene, is honesty - always open and not afraid to share her opinions. Her honesty is always handled in a professional matter.',
          'Helene thanks for acting and working for Dell Technologies, with the highest Quality & Integrity Helene would be a great asset to have on any team and she is an amazing colleague.',
        ],
      },
      {
        name: 'Per Sjösvärd',
        role: 'IT-chef, AB Stångåsstaden',
        date: '1 mars 2019',
        review: [
          'Helene arbetade som IT-projektledare hos Stångåstaden under 2018 och drev två av våra stora genomförandeprojekt, Stångåstadens Boende-app samt Stångåstadens nya digitala arbetsplats. Helenes engagemang och driv har varit till stor nytta för att slutföra dessa projekt. Helene är mycket social och har bidragit till ett bra arbetsklimat i projektgruppen samt på vår IT-avdelning.',
        ],
      },
      {
        name: 'David Wennberg',
        role: 'Business Unit Manager, DevPort AB',
        date: '5 september 2023',
        review: [
          'Jag har förmånen att ha Helene som projektledare för en inhouseleverans baserad från DevPort Öst ABs Linköpingskontor. Helene har en väldigt kort startsträcka och en naturlig, inneboende, bekväm tydlighet. Hon har en förmåga att fokusera och skala bort saker som inte behöver hända nu eller inte är viktiga.',
          'Helene leder ett väldigt tekniskt projekt med en stor dos lugn, trygghet, energi och engagemang. Hon har inte den tekniska domänkunskapen vi arbetar med i det här projektet, men listar snabbt ut sammanhang och relationer, både projektmässigt och mellanmänskliga.',
          'Det är väldigt trevligt att få arbeta med dig Helene - när du har dragit det här projektet i mål så hoppas jag att vi ses i något annat projekt längre fram!',
        ],
      },
    ],

    isLecturer: true,
    lecturerImg: 'people/lecturer_helene2.jpg',
    lecturerAlt: 'Helene njuter av solen sittandes på en sten i vattenbrynet',
    lecturerSecTxt: {
      sectionHeadingSmall: 'Våra föreläsare',
      sectionHeading: 'Helene',
    },
    lecturerShortText:
      'Lyssna på Helenes föreläsningar om projektledning, äventyr och livet som digital nomad.',
    lecturerLongerText: [
      'Helene vågade hoppa av sin fasta anställning mitt under pandemin för att bygga upp ett bolag hon visste skulle fungera. Helene ville driva sin konsultverksamhet från datorn och skapa en framgångsrik karriär – och hon ville göra det från den plats hon råkade befinna sig just då. Nu lever hon sin dröm som konsult och digital nomad på Sherpify.',
      'I sina föreläsningar delar Helene med sig av sin erfarenhet för att inspirera andra att våga gå sin egen väg för att följa sina drömmar.',
    ],
    lecturerSubjectHeading: 'Föreläser inom',
    lectureSubjects: ['Att vara en digital nomad'],
    isLecturerReviews: false,
    lecturerReviewSecTxt: {
      sectionHeadingSmall: 'Rekommendationer',
      sectionHeading: '',
      linkTxt: 'Läs hela texten',
      btnTxt: 'Visa mer',
    },
    lecturerReviews: [],
  },
  {
    id: 2,
    name: 'Pemba Sherpa',
    title: 'Inspirationsföreläsare',
    title2: 'Äventyrledare',
    imgUrlSquare: 'people/pemba_square2.jpg',
    imgSquareAlt: 'Pemba',
    mail: 'mailto:pemba@sherpify.se',
    linkedin: 'https://www.linkedin.com/in/pemba-sherpa-0b867824/',

    isConsult: false,
    consultantImg: 'people/pemba_square2.jpg',
    consultantAlt: '',
    consultingSecTxt: {
      sectionHeadingSmall: 'Våra konsulter',
      sectionHeading: '',
    },
    consultLongerText: [' '],
    projects: [
      {
        projectName: '',
        projectCompany: '',
        projectText: '',
      },
    ],
    isConsultingReviews: false,
    consultingReviewSecTxt: {
      sectionHeadingSmall: 'Rekommendationer',
      sectionHeading: '',
      linkTxt: 'Läs hela texten',
      btnTxt: 'Visa mer',
    },
    consultingReviews: [],

    isLecturer: true,
    lecturerImg: 'people/lecturer_pemba2.jpg',
    lecturerAlt: 'Pemba står i solen med snötäckta berg i bakgrunden',
    lecturerSecTxt: {
      sectionHeadingSmall: 'Våra föreläsare',
      sectionHeading: 'Pemba',
    },
    lecturerShortText:
      'Pembas förtrollande föreläsningar tar dig från Nepals bergstoppar till röda mattan i Hollywood för att slutligen landa i Kolmårdens trollskogar.',
    lecturerLongerText: [
      'Pembas familj är bönder och tillhör sherpafolket. Som barn fick Pemba hjälpa till att försörja familjen i stället för att gå i skolan, tills han en dag fick möjlighet att vara delaktig i en bergsexpedition i Himalaya.',
      'Idag har Pemba lång erfarenhet som bergsguide och han har lett flera bestigningar till toppen av Mount Everest. Pembas mod och förmåga att leda i extrema miljöer gör hans föreläsningar till ett spännande äventyr, där han utmanar och inspirerar gruppen till att arbeta tillsammans.',
    ],
    lecturerSubjectHeading: 'Föreläser inom',
    lectureSubjects: ['Ledarskap', 'Livsresan'],
    isLecturerReviews: false,
    lecturerReviewSecTxt: {
      sectionHeadingSmall: 'Rekommendationer',
      sectionHeading: 'Rubrik',
      linkTxt: 'Läs hela texten',
      btnTxt: 'Visa mer',
    },
    lecturerReviews: [
      // {
      //   name: 'Namn1',
      //   role: 'Roll',
      //   date: 'DD månad ÅÅÅÅ',
      //   review: [
      //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Labore et dolore magna aliqua',
      //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      //   ],
      // },
    ],
  },
];
export const peopleEng = [
  {
    id: 1,
    name: 'Helene Sherpa',
    title: 'Consultant in project management',
    title2: 'CEO Sherpify',
    imgUrlSquare: 'people/helene_square2.jpg',
    imgSquareAlt: 'Helene',
    mail: 'mailto:helene@sherpify.se',
    linkedin: 'https://www.linkedin.com/in/helenesherpa/',

    isConsult: true,
    consultantImg: 'people/consultant_helene2.jpg',
    consultantAlt: 'Helene is sitting in front of her desk in Nepal.',
    consultingSecTxt: {
      sectionHeadingSmall: 'Our consultants',
      sectionHeading: 'Helene defies status quo',
    },
    consultLongerText: [
      "With eager and the gaze constantly fixed forward, Helene has built up her consulting career within project management. Leading projects from start to finish is something that makes Helene's heart beat faster. The combination of managing and supporting the team, as well as finding smarter ways to reach the project goal for a client, is what drives Helene to continue forward.",
      'Helene found her passion for project management and business development when she got her dream consultant role at Saab 15 years ago, and since then, she has run projects for numerous companies. Her passion lies in being involved from the beginning through requirement analysis and strategic planning to implementation and successful completion together with the team and the client.',
    ],
    projects: [
      {
        projectName: 'Senior program manager',
        projectCompany: 'Dell Technologies',
        projectText:
          "Helene's role as a Senior Program Manager for Dell's transformation project involved overseeing multiple projects simultaneously, and her team was spread out across the Nordic region. This presented unique and challenging circumstances in order to achieve the project goals according to plan.",
      },
      {
        projectName: 'IT Project Manager',
        projectCompany: 'Stångåsstaden',
        projectText:
          'Stångåstaden hired Helene as a consultant to lead the development of a new app for the tenants. The project began with customer surveys and an analysis of potential solutions, eventually leading to the creation of an app for the client.',
      },
      {
        projectName: 'Senior Project Manager',
        projectCompany: 'DevPort',
        projectText:
          'Helene served as a senior project manager responsible for three teams responsible for building hardware, software, and Android apps to control a sensor system.',
      },
      {
        projectName: 'Project Manager',
        projectCompany: 'Saab',
        projectText:
          "Helene worked as the lead project manager to implement a new administrative system for Saab's Gripen business. She successfully took the project from requirements analysis to implementation, and the system has since been rolled out to all business units within Saab.",
      },
    ],
    isConsultingReviews: true,
    consultingReviewSecTxt: {
      sectionHeadingSmall: 'Recommendations',
      sectionHeading: 'We recommend Helene',
      linkTxt: 'Read more',
      btnTxt: 'Show more',
    },
    consultingReviews: [
      {
        name: 'Karina Visholm Andersen',
        role: 'Senior Service Delivery Manager, Dell Technologies',
        date: '17 October 2022',
        review: [
          'I have had the pleasure of working with Helene now for two large accounts within Dell. During this year!',
          'Helene’s skills are many but if I was to list just a few they would be: Commitment –Helene goes to great lengths to reach  the goal, she is determined and has a fantastic ability to stay focused on a task for a long period of time.',
          'When working with Helene you never have a dull moment. She is extremely eager, to get things completed. Upon completion follows a great bunch of high quality and an eye for detail. She has a great understanding of the business and is really creative and constantly comes up with ideas for improvements.',
          'Helene is contracted by Dell but acts as if we are one company and is highly appreciated by all members of the team and especially me. Helene is organized, structured, and very self-driven.',
          'What you can expect from Helene, is honesty - always open and not afraid to share her opinions. Her honesty is always handled in a professional matter.',
          'Helene thanks for acting and working for Dell Technologies, with the highest Quality & Integrity Helene would be a great asset to have on any team and she is an amazing colleague.',
        ],
      },
      {
        name: 'Per Sjösvärd',
        role: 'IT Manager, AB Stångåsstaden',
        date: '1 March 2019',
        review: [
          "Helene worked as an IT project manager at Stångåstaden in 2018 and led two of our major implementation projects, Stångåstaden's Housing App and Stångåstaden's new digital workplace. Helene's commitment and drive have been of great benefit in completing these projects. She is very sociable and has contributed to a good working atmosphere in the project team and within our IT department.",
        ],
      },
      {
        name: 'David Wennberg',
        role: 'Business Unit Manager, DevPort AB',
        date: '5 September 2023',
        review: [
          "I have the privilege of having Helene as a project manager for an in-house delivery based from DevPort East AB's Linköping office. Helene has a very short learning curve and a natural, inherent, comfortable clarity. She has an ability to focus and scale down things that don't need to happen now or aren't important.",
          "Helene is leading a highly technical project with a great deal of calm, confidence, energy, and commitment. She doesn't possess the technical domain knowledge we work with in this project, but she quickly grasps contexts and relationships, both in terms of project management and interpersonal dynamics.",
          "It's been very pleasant working with you, Helene. When you've successfully completed this project, I hope we'll meet in another project down the road!",
        ],
      },
    ],

    isLecturer: true,
    lecturerImg: 'people/lecturer_helene2.jpg',
    lecturerAlt:
      "Helene enjoys the sun while sitting on a stone by the water's edge.",
    lecturerSecTxt: {
      sectionHeadingSmall: 'Out lecturers',
      sectionHeading: 'Helene',
    },
    lecturerShortText:
      "Listen to Helene's lectures on project management, adventures, and the life of a digital nomad.",
    lecturerLongerText: [
      'Helene had the courage to leave her regular job in the midst of the pandemic to build a company she knew would work. She wanted to run her consulting business from her computer and create a successful career - all while doing it from wherever she happened to be at the time. Now, she is living her dream as a consultant and digital nomad at Sherpify.',
      'In her lectures, Helene shares her experience to inspire others to dare to forge their own path in pursuit of their dreams.',
    ],
    lecturerSubjectHeading: 'Lectures within',
    lectureSubjects: ['Being a digital nomad'],
    isLecturerReviews: false,
    lecturerReviewSecTxt: {
      sectionHeadingSmall: 'Recommendations',
      sectionHeading: '',
      linkTxt: 'Read more',
      btnTxt: 'Show more',
    },
    lecturerReviews: [],
  },
  {
    id: 2,
    name: 'Pemba Sherpa',
    title: 'Inspirational lecturer',
    title2: 'Adventure Leader',
    imgUrlSquare: 'people/pemba_square2.jpg',
    imgSquareAlt: 'Pemba',
    mail: 'mailto:pemba@sherpify.se',
    linkedin: 'https://www.linkedin.com/in/pemba-sherpa-0b867824/',

    isConsult: false,
    consultantImg: 'people/pemba_square2.jpg',
    consultantAlt: '',
    consultingSecTxt: {
      sectionHeadingSmall: 'Our consultants',
      sectionHeading: '',
    },
    consultLongerText: [' '],
    projects: [
      {
        projectName: '',
        projectCompany: '',
        projectText: '',
      },
    ],
    isConsultingReviews: false,
    consultingReviewSecTxt: {
      sectionHeadingSmall: 'Recommendations',
      sectionHeading: '',
      linkTxt: 'Read more',
      btnTxt: 'Show more',
    },
    consultingReviews: [],

    isLecturer: true,
    lecturerImg: 'people/lecturer_pemba2.jpg',
    lecturerAlt:
      'Pemba stands in the sun with snow-covered mountains in the background',
    lecturerSecTxt: {
      sectionHeadingSmall: 'Our lecturers',
      sectionHeading: 'Pemba',
    },
    lecturerShortText:
      "Pemba's enchanting lectures take you from the mountain peaks of Nepal to the red carpet in Hollywood, finally landing in the magical forests of Kolmården.",
    lecturerLongerText: [
      "Pemba's family are Sherpas and belong to a long line of farmers. As a child, Pemba had to help provide for the family instead of going to school until one day he got the opportunity to participate in a mountain expedition in the Himalayas.",
      "Today, Pemba has extensive experience as a mountain guide, and he has led several expeditions to the summit of Mount Everest. Pemba's courage and ability to lead in extreme environments make his lectures an exciting adventure, where he challenges and inspires the group to work together.",
    ],
    lecturerSubjectHeading: 'Lectures within',
    lectureSubjects: ['Leadership', 'Life journey'],
    isLecturerReviews: false,
    lecturerReviewSecTxt: {
      sectionHeadingSmall: 'Recommendations',
      sectionHeading: '',
      linkTxt: 'Read more',
      btnTxt: 'Show more',
    },
    lecturerReviews: [
      // {
      //   name: 'Namn1',
      //   role: 'Roll',
      //   date: 'DD månad ÅÅÅÅ',
      //   review: [
      //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Labore et dolore magna aliqua',
      //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      //   ],
      // },
    ],
  },
];
