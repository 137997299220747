import React from 'react';
import styles from './NewsCard.module.css';
import { BsArrowRight } from 'react-icons/bs';
import { GlobalContext } from '../../../GlobalContext';

export default function NewsCard(props) {
  const { setModalIsOpen } = GlobalContext();
  const data = { ...props.data };
  const image = require(`../../../images/${data.imgUrl}`);

  return (
    <article className={styles.cardWrapper}>
      <img src={image} alt={data.imgAlt} className={styles.img} />
      <p className={styles.date}>
        {data.month}, {data.year}
      </p>
      <h4 className={`${styles.intro} bodyTxt mediumBold`}>{data.intro}</h4>
      <div
        className={styles.link}
        onClick={() => {
          setModalIsOpen(true);
          props.handleModalContent(data);
        }}
      >
        {props.link}
        <BsArrowRight className={styles.arrowIcon} />{' '}
      </div>
    </article>
  );
}
