import React from 'react';
import styles from './BackgroundSection.module.css';
import { GlobalContext } from '../../GlobalContext';
import { backgroundSecTxt, backgroundSecTxtEng } from '../../Data/data';
import Heading from '../Heading/Heading';

export default function BackgroundSection() {
  const { userLanguage, deviceType } = GlobalContext();
  const text = userLanguage === 'sv' ? backgroundSecTxt : backgroundSecTxtEng;
  const image = require(`../../images/${text.imgUrl}`);

  return (
    <section className="background bgM1" id="background">
      <section className={`${styles.sectionWrapper} sectionWrapper`}>
        <div className={styles.txtWrapper}>
          <Heading
            data={text}
            position={
              deviceType === 'Desktop'
                ? 'flex-start'
                : deviceType === 'Tablet'
                ? 'center'
                : 'center'
            }
          />
          <div className="paragraphWrapper">
            {text.text.map((paragraph, index) => (
              <p className="bodyTxt" key={index}>
                {paragraph}
              </p>
            ))}
          </div>
        </div>
        <div className={styles.imgWrapper}>
          <img src={image} alt={text.imgAlt} className={styles.img} />
        </div>
      </section>
    </section>
  );
}
