import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AreaCard.module.css';
import { MdOutlineArrowForward } from 'react-icons/md';
import { MdOutlineDisabledByDefault } from 'react-icons/md';
import { FaUser, FaChalkboardTeacher } from 'react-icons/fa';
import { FaMountainSun } from 'react-icons/fa6';
import { GlobalContext } from '../../../GlobalContext';

export default function AreaCard(area) {
  const { scrollToSection } = GlobalContext();
  const data = { ...area.data };

  const IconRenderer = ({ iconName, className }) => {
    const iconMap = {
      consulting: <FaUser />,
      lectures: <FaChalkboardTeacher />,
      activities: <FaMountainSun />,
    };

    const selectedIcon = iconMap[iconName] || (
      <MdOutlineDisabledByDefault className={styles.areaIcon} />
    );
    const iconWithClassName = React.cloneElement(selectedIcon, {
      className: className,
    });

    return <>{iconWithClassName}</>;
  };

  return (
    <article className={styles.cardWrapper}>
      <div className={styles.contentWrapper}>
        <IconRenderer className={styles.areaIcon} iconName={data.icon} />
        <div className={styles.txtWrapper}>
          <h4 className="titleTxt largeTxt">{data.title}</h4>
          <p className={`${styles.txt} bodyTxt`}>{data.text}</p>
        </div>
      </div>
      <Link
        className={styles.link}
        to={data.link}
        aria-label={`Navigate to ${data.title}`}
        onClick={() => scrollToSection('intro')}
      >
        <MdOutlineArrowForward className={styles.arrowIcon} />
      </Link>
    </article>
  );
}
