import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './LandingSection.module.css';
import { GlobalContext } from '../../GlobalContext';
import { landingSecTxt, landingSecTxtEng } from '../../Data/data';

export default function LandingSection() {
  const { scrollToSection, userLanguage, deviceType } = GlobalContext();
  const location = useLocation();
  const [page, setPage] = useState('');

  const text = userLanguage === 'sv' ? landingSecTxt : landingSecTxtEng;

  useEffect(() => {
    switch (location.pathname) {
      case '/konsulting':
        setPage('consultingPage');
        break;
      case '/forelasningar':
        setPage('lecturesPage');
        break;
      case '/aktiviteter':
        setPage('activitiesPage');
        break;
      default:
        setPage('homePage');
    }
  }, [location]);

  const ImageHeading = () => {
    const largeHeading =
      page === 'consultingPage'
        ? text.textList[1].largeHeading
        : page === 'lecturesPage'
        ? text.textList[2].largeHeading
        : page === 'activitiesPage'
        ? text.textList[3].largeHeading
        : text.textList[0].largeHeading;

    const smallHeading =
      page === 'consultingPage'
        ? text.textList[1].smallHeading
        : page === 'lecturesPage'
        ? text.textList[2].smallHeading
        : page === 'activitiesPage'
        ? text.textList[3].smallHeading
        : text.textList[0].smallHeading;

    return (
      <div className={styles.overlay}>
        <h1 className={styles.heading}>
          {largeHeading}
          {smallHeading && <p className={styles.span}>{smallHeading}</p>}
        </h1>
      </div>
    );
  };

  const isActiveLink = (linkPath) => {
    return location.pathname === '/' + linkPath;
  };

  const HeroImage = () => {
    const imageArray = text.imgList.map((imgPath) =>
      require(`../../images/landing/${imgPath}`)
    );

    const preloadImages = (imageUrls) => {
      imageUrls.forEach((imageUrl) => {
        const img = new Image();
        img.src = imageUrl;
      });
    };

    preloadImages(imageArray.map((image) => image));

    const pageImage =
      page === 'consultingPage'
        ? imageArray[1]
        : page === 'lecturesPage'
        ? imageArray[2]
        : page === 'activitiesPage'
        ? imageArray[3]
        : imageArray[0];

    const componentStyle = {
      backgroundImage: `url(${pageImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: '100%',
      minHeight: deviceType === 'Mobile' ? '97vh' : '100vh',
      transition: 'background-image 1.5s ease-in-out',
      zIndex: '2',
      position: 'relative',
    };

    return <div style={componentStyle}></div>;
  };

  return (
    <section id="landing" className={styles.sectionWrapper}>
      <div>
        <HeroImage />
        <ImageHeading />
      </div>
      <div className={styles.linkWrapper}>
        <Link
          className={
            isActiveLink('konsulting')
              ? styles.link + ' ' + styles.active
              : styles.link
          }
          to="konsulting"
          onClick={() => scrollToSection('intro')}
        >
          {text.linkConsult}
        </Link>
        <Link
          className={
            isActiveLink('forelasningar')
              ? styles.link + ' ' + styles.active
              : styles.link
          }
          to="forelasningar"
          onClick={() => scrollToSection('intro')}
        >
          {text.linkLectures}
        </Link>
        <Link
          className={
            isActiveLink('aktiviteter')
              ? styles.link + ' ' + styles.active
              : styles.link
          }
          to="aktiviteter"
          onClick={() => scrollToSection('intro')}
        >
          {text.linkActivities}
        </Link>
      </div>
    </section>
  );
}
