import React from 'react';
import styles from './AreasSection.module.css';
import { GlobalContext } from '../../GlobalContext';
import { areasSecTxt, areasSecTxtEng, areas, areasEng } from '../../Data/data';
import ButtonContact from '../ButtonContact/ButtonContact';
import Heading from '../Heading/Heading';
import AreaCard from './AreaCard/AreaCard';

export default function AreasSection() {
  const { deviceType, userLanguage } = GlobalContext();
  const text = userLanguage === 'sv' ? areasSecTxt : areasSecTxtEng;
  const content = userLanguage === 'sv' ? areas : areasEng;

  return (
    <div id="areas" className="background bgM1">
      <section className={`${styles.sectionWrapper} sectionWrapper`}>
        <article className={styles.introductionWrapper}>
          <Heading
            data={text}
            position={
              deviceType === 'Desktop'
                ? 'flex-start'
                : deviceType === 'Tablet'
                ? 'flex-start'
                : 'center'
            }
          />
          <p className="bodyTxt mediumTxt">{text.bodyTxt}</p>
          <ButtonContact
            value={text.btnTxt}
            align={deviceType === 'Mobile' ? 'center' : 'flex-start'}
            margin="2.5rem"
          />
        </article>
        {content.map((area, index) => (
          <AreaCard key={index} data={area} />
        ))}
      </section>
    </div>
  );
}
