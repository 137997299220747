import React, { useEffect } from 'react';
import { GlobalContext } from '../../GlobalContext';
import { homeIntroTxt, homeIntroTxtEng } from '../../Data/data';
import IntroSection from '../IntroSection/IntroSection';
import AreasSection from '../AreasSection/AreasSection';
import AboutSection from '../AboutSection/AboutSection';
import NewsSection from '../NewsSection/NewsSection';
import MoodSection from '../MoodSection/MoodSection';
import BackgroundSection from '../BackgroundSection/BackgroundSection';
import WeWantMoreSection from '../WeWantMoreSection/WeWantMoreSection';

export default function HomePage() {
  const { shouldScroll, scrollToSection, sectionId, userLanguage } =
    GlobalContext();
  const text = userLanguage === 'sv' ? homeIntroTxt : homeIntroTxtEng;

  useEffect(() => {
    if (sectionId === 'landing') scrollToSection('landing');
    if (sectionId === 'areas') scrollToSection('areas');
    if (sectionId === 'background') scrollToSection('background');
    if (sectionId === 'contact') scrollToSection('contact');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldScroll]);

  return (
    <main className="pageWrapper">
      <IntroSection data={text} />
      <AreasSection />
      <MoodSection />
      <NewsSection />
      <BackgroundSection />
      <WeWantMoreSection />
      <AboutSection />
    </main>
  );
}
