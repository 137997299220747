import React from 'react';
import { GlobalContext } from '../../GlobalContext';
import { lecturesIntroTxt, lecturesIntroTxtEng } from '../../Data/data';
import Intro from '../IntroSection/IntroSection';
import LecturesSection from '../LecturesSection/LecturesSection';
import LecturerSection from '../LecturerSection/LecturerSection';

export default function LecturesPage() {

  const { userLanguage } = GlobalContext();
  const text = userLanguage === 'sv' ? lecturesIntroTxt : lecturesIntroTxtEng;

  return (
    <main className='pageWrapper'>
      <Intro data={text}/>
      <LecturesSection />
      <LecturerSection />
    </main>
  )
}
