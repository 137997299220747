//PREPAIRED FOT THE REVIEWS SECTION

import React from 'react';
import { GlobalContext } from '../../GlobalContext';
import { activitiesIntroTxt, activitiesIntroTxtEng } from '../../Data/data';
// import { activitiesReviewSecTxt, activitiesReviewSecTxtEng, activitiesReviews, activitiesReviewsEng } from '../../Data/data';
import Intro from '../IntroSection/IntroSection';
import ActivitiesSection from '../ActivitiesSection/ActivitiesSection';
import ContactBanner from '../ContactBanner/ContactBanner';
// import ReviewsSection from '../ReviewsSection/ReviewsSection';

export default function ActivitiesPage() {
  const { userLanguage } = GlobalContext();
  const text =
    userLanguage === 'sv' ? activitiesIntroTxt : activitiesIntroTxtEng;
  // const textReview = userLanguage === 'sv' ? activitiesReviewSecTxt : activitiesReviewSecTxtEng;
  // const contentReview = userLanguage === 'sv' ? activitiesReviews : activitiesReviewsEng;

  return (
    <main className="pageWrapper">
      <Intro data={text} />
      <ActivitiesSection />
      <ContactBanner />
      {/* <ReviewsSection text={textReview} content={contentReview} /> */}
    </main>
  );
}
