import React from 'react';
import styles from './WeWantMoreSection.module.css';
import { GlobalContext } from '../../GlobalContext';
import { weWantMoreSecTxt, weWantMoreSecTxtEng } from '../../Data/data';
import Heading from '../Heading/Heading';

export default function WeWantMoreSection() {
  const { userLanguage, deviceType } = GlobalContext();
  const text = userLanguage === 'sv' ? weWantMoreSecTxt : weWantMoreSecTxtEng;
  const image = require(`../../images/we_want_more/${text.imgUrl1}`);
  const image2 = require(`../../images/we_want_more/${text.imgUrl2}`);
  const logo = require(`../../images/aan_logos/${text.logoUrl}`);

  return (
    <section id="" className={`${styles.sectionWrapper} sectionWrapper`}>
      <Heading position="center" data={text} />
      <p
        className={`${styles.introTxt} bodyTxt centerTxt mediumTxt mediumBold`}
      >
        {text.introTxt}
      </p>
      <div className={styles.contentWrapper}>
        <div className={styles.imgWrapper}>
          <img src={image} alt={text.imgAlt1} className={styles.img} />
          {deviceType === 'Desktop' && (
            <img src={image2} alt={text.imgAlt2} className={styles.img} />
          )}
        </div>
        <div className="paragraphWrapper">
          {text.bodyTxt.map((paragraph, index) => (
            <p className="bodyTxt" key={index}>
              {paragraph}
            </p>
          ))}
          <img src={logo} alt="" className={styles.logo} />
          <p className="bodyTxt centerTxt">
            {text.supportTxt1}
            <a
              className={styles.link}
              href={`http://www.${text.supportLinkTxt}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {text.supportLinkTxt}
            </a>
            {text.supportTxt2}
          </p>
          {text.bodyTxt2.map((p, index) => (
            <p className="bodyTxt" key={index}>
              {p}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
}
