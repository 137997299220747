import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useForm, ValidationError } from '@formspree/react';
import styles from './ContactSection.module.css';
import { GlobalContext } from '../../GlobalContext';
import { contactSecTxt, contactSecTxtEng } from '../../Data/data';
import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import GdprModal from '../GdprModal/GdprModal';

export default function ContactSection() {
  const { userLanguage, gdprModalIsOpen, setGdprModalIsOpen } = GlobalContext();
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [state, handleSubmit] = useForm('xaygbnzk');
  const formRef = useRef(null);
  const text = userLanguage === 'sv' ? contactSecTxt : contactSecTxtEng;

  useEffect(() => {
    if (state.submitting) {
      setIsSubmit(true);
    }
  }, [state.submitting]);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleReadMoreClick = (event) => {
    event.preventDefault();
    setGdprModalIsOpen(true);
  };

  const resetForm = () => {
    formRef.current.reset();
  };

  const handleNewForm = () => {
    setIsChecked(false);
    resetForm();
    setIsSubmit(false);
  };

  return (
    <section className="background bgM1">
      <div id="contact" className={`${styles.sectionWrapper} sectionWrapper`}>
        <Heading position="center" data={text} />
        <form
          ref={formRef}
          className={styles.formWrapper}
          onSubmit={handleSubmit}
        >
          {state.succeeded && isSubmit && (
            <div className={styles.submitted}>
              <div className={styles.txtWrapper}>
                {text.submitTxt.map((text) => (
                  <p className="bodyTxt centerTxt">{text}</p>
                ))}
              </div>
              <button
                type="button"
                className={styles.button}
                onClick={() => handleNewForm()}
              >
                {text.submitBtn}
              </button>
            </div>
          )}
          {!isSubmit && (
            <div className={styles.formWrapper}>
              <p className="bodyTxt">{text.infoTxt}</p>
              <input
                className={styles.formInput}
                id="name"
                type="name"
                name="name"
                placeholder={text.placeholderName}
                required
              />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />
              <input
                className={styles.formInput}
                id="company"
                type="company"
                name="company"
                placeholder={text.placeholderCompany}
                required
              />
              <ValidationError
                prefix="Company"
                field="company"
                errors={state.errors}
              />
              <input
                className={styles.formInput}
                id="email"
                type="email"
                name="email"
                placeholder={text.placeholderEmail}
                required
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              <input
                className={styles.formInput}
                id="phone"
                type="phone number"
                name="phone"
                placeholder={text.placeholderPhoneNumber}
                required
              />
              <ValidationError
                prefix="Phone"
                field="phone"
                errors={state.errors}
              />
              <textarea
                className={styles.textarea}
                id="message"
                name="message"
                placeholder={text.placeholderMessage}
                required
              />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
              <label className={styles.checkboxWrapper} htmlFor="_optin">
                <input
                  id="_optin"
                  type="checkbox"
                  name="_optin"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <span></span>
                <div className={styles.gdprWrapper}>
                  <p className="bodyTxt smallTxt">
                    {text.gdprTxt}
                    <span
                      className={`${styles.readMore} bodyTxt smallTxt`}
                      onClick={handleReadMoreClick}
                    >
                      {text.gdprLink}
                    </span>
                  </p>
                </div>
              </label>
              <ValidationError
                prefix="_optin"
                field="_optin"
                errors={state.errors}
              />
              <div className={styles.btnWrapper}>
                <Button
                  value={text.btnTxt}
                  isDisabled={!isChecked && !state.submitting}
                />
              </div>
            </div>
          )}
        </form>
      </div>
      <Modal
        isOpen={gdprModalIsOpen}
        onRequestClose={() => setGdprModalIsOpen(false)}
        className="modalWrapper"
        overlayClassName="modalOverlay"
      >
        <GdprModal />
      </Modal>
    </section>
  );
}
