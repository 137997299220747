import React from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import styles from './LecturesSection.module.css';
import { FaChalkboardTeacher } from 'react-icons/fa'; 
import { GlobalContext } from '../../GlobalContext';
import { lecturesSecTxt, lecturesSecTxtEng, lectures, lecturesEng } from '../../Data/data';
import Heading from '../Heading/Heading';
import ButtonContact from '../ButtonContact/ButtonContact';
import LectureCard from './LectureCard/LectureCard';
import PopupModal from '../PopupModal/PopupModal';

export default function LecturesSection() {

    const { userLanguage, modalIsOpen, setModalIsOpen, deviceType } = GlobalContext();
    const [modalContent, setModalContent] = useState();
    const text = userLanguage === 'sv' ? lecturesSecTxt : lecturesSecTxtEng;
    const content = userLanguage === 'sv' ? lectures : lecturesEng;

    const handleModalContent = (content) => {
        setModalContent(content)
    }
    
    return (
        <div className='background bgM1'>
            <section className={`${styles.sectionWrapper} sectionWrapper`}>
                <Heading data={text} position={deviceType === 'Desktop' ? 'flex-start' : deviceType === 'Tablet' ? 'flex-start' : 'center'} />
                <div className={styles.contentWrapper}>
                    <article className={styles.infoWrapper}>
                        <FaChalkboardTeacher className={styles.icon}/>
                        <div className={styles.txtWrapper}>
                            <h4 className='largeTxt whiteTxt'>{text.infoHeading}</h4>
                            <div className='paragraphWrapper'>
                                {text.introTxt.map((paragraph, index) => (<p className='bodyTxt leftTxt whiteTxt' key={index}>{paragraph}</p>))}
                            </div>
                        </div>
                        <ButtonContact value={text.btnTxt}/>
                    </article>
                    <div className={styles.lectureCardWrapper}>
                        {content.map((lecture, index) => index <= 1 && <LectureCard key={index} data={lecture} handleModalContent={handleModalContent}/>)}
                    </div>
                    <div className={styles.lectureCardWrapper2}>
                        {content.map((lecture, index) => index > 1 && <LectureCard key={index} data={lecture} styling={'second'} handleModalContent={handleModalContent}/>)}
                    </div>
                </div>
            </section>
            <Modal         
                isOpen = {modalIsOpen}
                onRequestClose = {() => setModalIsOpen(false)}
                className = 'modalWrapper'
                overlayClassName = 'modalOverlay'>
                    <PopupModal modalType='lectures' content={modalContent}/>
            </Modal>
        </div>
    )
}
